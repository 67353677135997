import React from "react";
import error from "../image/error.svg"

function Hint({ onClose,title,message }) {
  return (
    <div className='bg-black/70 fixed inset-0 flex justify-center  z-40'>
      <div className='w-[280px] sm:w-[400px] h-[260px] relative bg-white z-10 p-[24px] flex flex-col rounded-md mt-20'>
        <button 
          className="absolute cross w-[24px] h-[24px] right-2 top-2"
          onClick={onClose} 
          >
        </button>
        <img src={error} alt="" className=" w-[40px] h-[40px] mb-6" />
        <div>
          <p className='text-base font-bold'>{title}</p>
          <p className='text-base text-[#475467]'>{message}</p>
        </div>
        <button className="rounded-xl w-[232px] sm:w-[352px] h-[44px] text-white bg-btn01 absolute bottom-[24px]" onClick={onClose}>繼續上傳</button>
      </div>
    </div>
  );
}


export default Hint;

function Incomplete({ onClose }) {
  return (
    <div className='bg-black/70 fixed inset-0 flex justify-center  z-40'>
      <div className='w-[280px] sm:w-[400px] h-[344px] relative bg-white z-10 p-[24px] flex flex-col rounded-md mt-20'>
        <button 
          className="absolute cross w-[24px] h-[24px] right-2 top-2"
          onClick={onClose} 
          >
        </button>
        <img src={error} alt="" className=" w-[40px] h-[40px] mb-6" />
        <div className="flex flex-col gap-y-[20px]">
        <div className="w-[232px] sm:w-[352px]">
          <p className='text-base font-bold mb-2'>填寫資料或上傳檔案不齊全</p>
          <ul className=" list-disc pl-6 mb-2">
            <li  className='text-[14px] sm:text-base text-[#475467]' >請確認填寫資料是否輸入完整或格式正確</li>
            <li  className='text-[14px] sm:text-base text-[#475467]' >若有上傳附資料，應與勾選的檔案數量相同或以上。</li>
          </ul>
          <p className='text-[14px] sm:text-base text-[#475467]'>請檢查上傳的檔案是否為以下格式 : .jpeg、.png、.HEIF、.HEIC、.gif</p>
        </div>
        <div>
        <button className=" rounded-[30px] w-[232px] sm:w-[352px] h-[44px] text-white bg-btn01  bottom-[24px]" onClick={onClose}>繼續上傳</button>
        </div>
        </div>
      </div>
    </div>
  );
}

export {Incomplete}
function SuccessHint() {
  return (
    <div className='bg-black/70 fixed inset-0 flex justify-center items-center  z-40'>
      <div className='w-[400px] mx-4 h-[184px] relative bg-white z-10 px-[24px] py-[30px] flex flex-col rounded-md'>
        <div>
          <p className='text-[24px] sm:text-[32px] font-bold mb-2'>提交成功</p>
          <p className='text-base sm:text-[24px] '>可以從<span className="font-bold">進度查詢</span>頁面查詢此申請單的狀態。</p>
        </div>
      </div>
    </div>
  );
}

export {SuccessHint}