import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Mandarin as zh } from "flatpickr/dist/l10n/zh.js";
import { useAdminApplications, useApplicationExcel } from "../hook/useApi";
import {
  getApplicationTypeText,
  getProcessText,
  getPickupStatusText,
} from "../component/TextConverters";
import search from "../images/search.svg";
import arrowleft from "../images/arrowleft.svg";
import logoutpic from "../images/logout.svg";
import backhome from "../images/backhome.svg";
import arrowright from "../images/arrowright.svg";
import arrowleftGray from "../images/arrowleft_gray.svg";
import arrowrightGray from "../images/arrowright_gray.svg";
import hasMoreArrow from "../images/hasMore.svg";
import hasMoreArrowGray from "../images/hasMore_gray.svg";

function DataManagementPage() {
  const [selectedTab, setSelectedTab] = useState("incomplete");
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [expandedItemId, setExpandedItemId] = useState(null);

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) {
      // setCurrentPage(Number(savedPage));
      // console.log("savedPage", savedPage);
    } else {
      setCurrentPage(1);
    }
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setSelectedTab(savedTab);
    }
    return () => {
      setDataList([]);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };

      const startDate = selectedDates[0].toLocaleDateString("sv-SE", options);
      const endDate = selectedDates[1].toLocaleDateString("sv-SE", options);

      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
    }
  };

  const { data, isLoading } = useAdminApplications(
    selectedTab === "complete"
      ? true
      : selectedTab === "incomplete"
        ? false
        : undefined,
    debouncedSearchTerm,
    startDate,
    endDate,
    currentPage,
    8
  );

  const { refetch } = useApplicationExcel(
    selectedTab === "complete"
      ? true
      : selectedTab === "incomplete"
        ? false
        : undefined,
    debouncedSearchTerm,
    startDate,
    endDate
  );

  const handleDownload = async () => {
    try {
      const response = await refetch();

      if (response && response.data) {
        console.log("API Response:", response);

        const blob = new Blob([response.data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        console.log("Blob:", blob);

        const headers = response.headers || {};
        const contentDisposition = headers["content-disposition"];

        let fileName =
          startDate && endDate ? `${startDate} to ${endDate}.xlsx` : "All.xlsx";
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename\*=UTF-8''(.+)/);
          if (matches && matches[1]) {
            fileName = decodeURIComponent(matches[1]);
          }
        }

        const url = window.URL.createObjectURL(blob);
        console.log("URL:", url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("沒有返回有效的檔案數據");
      }
    } catch (error) {
      console.error("檔案下載失敗", error);
    }
  };

  useEffect(() => {
    if (data) {
      setDataList((prevData) => [...prevData, ...data.data]);
      setMaxPage(data.total_pages || 1);
      setHasMore(currentPage < data.total_pages);
    }
  }, [data, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= maxPage) {
      setCurrentPage(newPage);
      localStorage.setItem("currentPage", newPage); // Save the current page to localStorage
      setDataList([]);
    }
  };

  useEffect(() => {
    if (data && data.total_pages) {
      setMaxPage(data.total_pages || 1);
    } else {
      setMaxPage(1);
    }
  }, [data]);

  const getPageNumbers = () => {
    let pages = [];
    let startPage, endPage;

    if (maxPage <= 4) {
      startPage = 1;
      endPage = maxPage;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage + 2 >= maxPage) {
        startPage = maxPage - 3;
        endPage = maxPage;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1);
    setDataList([]);
    localStorage.setItem("selectedTab", tab);
  };

  const [showDiv, setShowDiv] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("login_time");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("selectedTab");

    setShowDiv(true);

    setTimeout(() => {
      setShowDiv(false);
      navigate("/");
    }, 1500);
  };

  return (
    <div className="bg-[#F7F7F9] w-full min-h-screen max-h-max">
      <div
        className={`flex w-full h-[52px] sm:h-[108px] z-30 sticky top-0 bg-white items-center justify-between py-6 px-4 sm:px-6 lg:px-16 shadow-md`}
      >
        <div className="flex w-auto h-10 justify-center">
          <div
            className="w-auto h-10 flex items-center cursor-pointer"
            onClick={handleLogout}
          >
            <img alt="logout" src={logoutpic} />
            <p className="hidden md:flex text-xl font-semibold text-black pl-1">
              登出
            </p>
          </div>
        </div>
        <div className="flex flex-row xl:flex-col items-center">
          <div className="text-base sm:text-xl lg:text-2xl font-bold text-black font-roboto">
            Who's Out-
            <span className="font-bold text-base sm:text-xl md:hidden">
              後台
            </span>
          </div>
          <p className="hidden md:flex text-xl lg:text-2xl font-bold text-black font-roboto">
            收件/回件後台
          </p>
        </div>
        <div className="w-auto h-10 justify-center">
          <div className="md:w-[72px] h-10 flex items-center cursor-default">
            <p className="hidden md:flex text-xl font-semibold text-btn01 md:pr-[6px]">
              後台
            </p>
            <img alt="logout" src={backhome} className="fill-btn01" />
          </div>
        </div>
      </div>
      {showDiv && (
        <div className="bg-black/70 fixed inset-0 flex justify-center items-center z-30">
          <div className="w-[90%] sm:w-[400px] h-[184px] bg-white flex flex-col rounded-xl p-6 relative">
            <div className="check w-8 h-8 bg-no-repeat bg-contain mb-4"></div>
            <p className="text-lg text-[#101828] font-bold w-[243px] mb-1">
              登出成功
            </p>
            <p className="text-sm text-[#475467] w-[350px]">您已成功登出。</p>
            <div
              className="cross w-6 h-6 bg-no-repeat bg-contain absolute top-6 right-6 cursor-pointer"
              onClick={() => setShowDiv(false)}
            ></div>
          </div>
        </div>
      )}
      <div className="flex flex-col items-center w-full px-4 py-6 relative">
        <img
          alt="arrowright"
          src={arrowrightGray}
          className="hidden sm:block h-4 absolute top-[154px] lg:top-[104px] right-6 xl:hidden"
        />
        <div className="flex flex-col lg:flex-row items-center sm:justify-between w-full xl:w-[1320px]">
          <div className="flex items-center">
            <div className="flex w-full sm:w-[375px] relative">
              <motion.div
                className="absolute bottom-0 left-0 h-0.5 bg-btn01"
                initial={false}
                animate={{
                  x:
                    selectedTab === "all"
                      ? "0%"
                      : selectedTab === "incomplete"
                        ? "100%"
                        : "200%",
                }}
                transition={{ type: "spring", stiffness: 300, damping: 24 }}
                style={{ width: "33.33%" }}
              />
              {["all", "incomplete", "complete"].map((tab) => (
                <div
                  key={tab}
                  className="group flex flex-col items-center w-1/3 border-b-2 border-[#D9D9D9] hover:border-btn01/40 cursor-pointer"
                  onClick={() => handleTabChange(tab)}
                >
                  <div
                    className={`font-semibold mb-2 ${
                      selectedTab === tab
                        ? "text-btn01"
                        : "text-main-gray group-hover:text-btn01/40"
                    }`}
                  >
                    {tab === "all"
                      ? "全部"
                      : tab === "incomplete"
                        ? "未完成"
                        : "已完成"}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex relative w-full sm:w-auto ml-4">
              <img
                alt="search"
                src={search}
                className="absolute top-[10px] left-2 w-5 h-5"
              />
              <input
                className="w-full sm:w-[216px] h-10 border rounded-[10px] pl-8 focus:border-[1px] focus:border-btn01 focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)]"
                placeholder="搜尋編號或姓名..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="flex items-center mt-3 lg:mt-0">
            <Flatpickr
              className="w-full sm:w-[216px] h-10 border rounded-[10px] focus:border-[1px] pl-3 focus:border-btn01 focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] cursor-pointer"
              options={{
                mode: "range", // 設置為日期區間選擇模式
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "Y-m-d",
                enableTime: false,
                locale: zh,
              }}
              onChange={handleDateChange}
              placeholder="請選擇日期區間"
            />
            <button
              className="border border-btn01 text-btn01 w-[84px] h-[38px] rounded-lg bg-white ml-4"
              onClick={handleDownload}
            >
              下載
            </button>
          </div>
        </div>
        <div className="hidden sm:flex flex-col items-center mt-6 w-full xl:w-[1320px] overflow-x-auto">
          <div className="flex flex-col h-[677px] w-full">
            <div className="bg-main-gray w-[1320px] h-[45px] text-white text-center flex items-center text-lg mb-2">
              <div className="sticky left-0 z-10 bg-inherit w-[100px]">
                編號
                <img
                  alt="arrowleft"
                  src={arrowleftGray}
                  className="h-4 absolute top-[6px] right-0 xl:hidden"
                />
              </div>
              <p className="w-[110px]">日期</p>
              <p className="w-[100px]">姓名</p>
              <p className="w-[100px]">送件類別</p>
              <p className="w-[110px]">辦理項目</p>
              <p className="w-[100px]">件數</p>
              <p className="w-[100px]">批准狀態</p>
              <p className="w-[100px]">取件狀態</p>
              <p className="w-[100px]">取件人</p>
              <p className="w-[100px]">辦理狀態</p>
              <p className="w-[100px]">送件狀態</p>
              <p className="w-[100px]">送件人</p>
              <p className="w-[100px]">減少碳排</p>
            </div>
            {data &&
              data.data.map((item) => (
                <Link
                  to={`/applicationdetail/${item.application_id}`}
                  key={item.application_id}
                  className="bg-white hover:bg-[#F2F8FA] w-[1320px] h-[70px] mb-2 shadow-md flex items-center"
                >
                  <p className="text-main-gray text-lg h-full flex items-center justify-center sticky left-0 z-10 bg-inherit shadow-sm xl:shadow-none w-[100px]">
                    {item.application_id}
                  </p>
                  <p className="text-main-gray text-center text-lg w-[110px]">
                    {item.application_date}
                  </p>
                  <p className="text-main-gray text-center text-lg truncate w-[100px]">
                    {item.name}
                  </p>
                  <p className="text-main-gray text-center text-lg w-[100px]">
                    {getProcessText(item.process_type)}
                  </p>
                  <p className="text-main-gray text-center text-lg w-[110px]">
                    {getApplicationTypeText(item.application_type)}
                  </p>
                  <p className="text-main-gray text-center text-lg w-[100px]">
                    {item.quantity}
                  </p>
                  <div className="text-center text-lg flex justify-center w-[100px]">
                    {item.is_approved ? (
                      <p className="bg-[#E9F4DC] w-[80px] h-8 text-[14px] text-[#71AC4D] rounded-md border border-[#71AC4D]">
                        已批准
                      </p>
                    ) : (
                      <p className="bg-[#FFC5C5] w-[80px] h-8 text-[14px] text-[#EE1D52] rounded-md border border-[#EE1D52]">
                        未批准
                      </p>
                    )}
                  </div>
                  <div className="text-main-gray text-center text-lg w-[100px]">
                    {getPickupStatusText(item.pickup_status)}
                  </div>
                  <p className="text-main-gray text-center text-lg w-[100px]">
                    {item.pickup_person}
                  </p>
                  <div className="flex justify-center text-center text-lg w-[100px]">
                    {(() => {
                      if (
                        item.is_picked_up === false &&
                        item.is_completed === false
                      ) {
                        return (
                          <div className="bg-white w-[80px] h-8 text-[14px] text-[#A3A3A3] rounded-md border border-[#A3A3A3]">
                            未辦理
                          </div>
                        );
                      } else if (
                        item.is_picked_up === true &&
                        item.is_completed === false
                      ) {
                        return (
                          <div className="bg-white w-[80px] h-8 text-[14px] text-[#ee1d52] rounded-md border border-[#ee1d52]">
                            辦理中
                          </div>
                        );
                      } else if (
                        item.is_picked_up === true &&
                        item.is_completed === true
                      ) {
                        return (
                          <div className="bg-[#F2F8FA] w-[80px] h-8 text-[14px] text-btn01 rounded-md border border-btn01">
                            已辦理
                          </div>
                        );
                      }
                    })()}
                  </div>
                  <p className="text-main-gray text-center text-lg w-[100px]">
                    {item.is_delivered ? "已送件" : "未送件"}
                  </p>
                  <p className="text-main-gray text-center text-lg w-[100px]">
                    {item.delivery_person}
                  </p>
                  <div className="flex flex-col w-[100px]">
                    <p className="text-[#71AC4D] text-center text-lg">
                      {item.CO2_emission_reduction}kg
                    </p>
                    <p className="text-[#71AC4D] text-center text-lg">CO2e</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <div className="flex flex-col w-full h-auto items-center sm:hidden">
          {dataList &&
            dataList.map((item, index) => (
              <div
                className="mt-4 w-full cursor-pointer"
                key={item.application_id}
                onClick={() =>
                  setExpandedItemId(
                    expandedItemId === item.application_id
                      ? null
                      : item.application_id
                  )
                }
              >
                <div
                  className={`w-full h-10 rounded-t-[10px] flex items-center justify-between px-4 ${index % 2 === 0 ? "bg-main-gray" : "bg-[#F2F1E8]"} text-white`}
                >
                  <div
                    className={`flex w-full justify-between  ${index % 2 === 0 ? "text-white" : "text-main-gray"}`}
                  >
                    <div className="font-semibold">
                      編號：
                      <span className="font-normal">{item.application_id}</span>
                    </div>
                    <p className="">{item.application_date}</p>
                  </div>
                </div>
                <div
                  className={`w-full h-auto rounded-b-[10px] flex flex-col border bg-white p-4  ${index % 2 === 0 ? "border-main-gray" : "border-[#F2F1E8]"}`}
                >
                  <div className="flex w-full justify-between">
                    <p className="text-main-gray font-semibold">姓名：</p>
                    <p className="text-main-gray truncate w-[80%] text-end">
                      {item.name}
                    </p>
                  </div>
                  <div className="flex w-full justify-between mt-1">
                    <p className="text-main-gray font-semibold">送件類別：</p>
                    <p className="text-main-gray">
                      {getProcessText(item.process_type)}
                    </p>
                  </div>
                  <div className="flex w-full justify-between mt-1">
                    <p className="text-main-gray font-semibold">辦理項目：</p>
                    <p className="text-main-gray">
                      {getApplicationTypeText(item.application_type)}
                    </p>
                  </div>
                  <div className="flex w-full justify-between mt-1">
                    <p className="text-main-gray font-semibold">件數：</p>
                    <p className="text-main-gray">{item.quantity}</p>
                  </div>
                  {expandedItemId === item.application_id && (
                    <>
                      <div className="border-b w-full my-3 mx-auto"></div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">
                          批准狀態：
                        </p>
                        <div className="text-main-gray">
                          {item.is_approved ? (
                            <div className="bg-[#E9F4DC] w-[60px] h-8 text-[14px] flex justify-center items-center text-[#71AC4D] rounded-md border border-[#71AC4D]">
                              已批准
                            </div>
                          ) : (
                            <div className="bg-[#FFC5C5] w-[60px] h-8 text-[14px] flex justify-center items-center text-[#EE1D52] rounded-md border border-[#EE1D52]">
                              未批准
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">
                          取件狀態：
                        </p>
                        <p className="text-main-gray">
                          {getPickupStatusText(item.pickup_status)}
                        </p>
                      </div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">取件人：</p>
                        <p className="text-main-gray">{item.pickup_person}</p>
                      </div>
                      <div className="border-b w-full my-3 mx-auto"></div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">
                          辦理狀態：
                        </p>
                        <div className="text-main-gray">
                          {item.is_completed ? (
                            <div className="bg-[#F2F8FA] w-[60px] h-8 text-[14px] flex justify-center items-center text-btn01 rounded-md border border-btn01">
                              已辦理
                            </div>
                          ) : (
                            <div className="bg-white w-[60px] h-8 text-[14px] flex justify-center items-center text-[#A3A3A3] rounded-md border border-[#A3A3A3]">
                              未辦理
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">
                          送件狀態：
                        </p>
                        <p className="text-main-gray">
                          {item.is_delivered ? "已送件" : "未送件"}
                        </p>
                      </div>
                      <div className="flex w-full justify-between mt-1">
                        <p className="text-main-gray font-semibold">送件人：</p>
                        <p className="text-main-gray">{item.delivery_person}</p>
                      </div>
                      <Link
                        to={`/applicationdetail/${item.application_id}`}
                        className="text-btn01 text-lg font-semibold w-full h-11 mt-4 border border-btn01 rounded-lg flex justify-center items-center"
                      >
                        進入訂單
                      </Link>
                    </>
                  )}
                </div>
              </div>
            ))}
          {hasMore && !isLoading && (
            <div
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              className="cursor-pointer text-btn01 text-sm my-4 flex items-center"
            >
              <img alt="hasMore" src={hasMoreArrow} className="h-4 mr-1" />
              點選載入更多
            </div>
          )}
          {!hasMore && (
            <div className="text-[#A3A3A3] text-sm mt-4 flex items-center">
              <img
                alt="nothasmore"
                src={hasMoreArrowGray}
                className="h-4 mr-1"
              />
              沒有更多載入
            </div>
          )}
        </div>
        <div className="hidden sm:flex justify-between w-full xl:w-[1320px] mt-7">
          <p className="text-main-gray text-[14px]">一頁總共顯示8件</p>
          <div className="flex items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-10 h-10 bg-white flex justify-center items-center rounded mr-3 cursor-pointer"
            >
              <img alt="arrowleft" src={arrowleft} className="h-4" />
            </button>
            {currentPage > 2 && maxPage > 5 && (
              <>
                <button
                  onClick={() => handlePageChange(1)}
                  className={`font-medium font-poppins w-10 h-10 flex justify-center items-center rounded mr-3 cursor-pointer ${currentPage === 1 ? "bg-btn01 text-white" : "bg-white text-btn01 border border-btn01"}`}
                >
                  1
                </button>
                {currentPage > 3 && <span className="mr-3">...</span>}
              </>
            )}
            {getPageNumbers().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`font-medium font-poppins w-10 h-10 flex justify-center items-center rounded mr-3 cursor-pointer ${currentPage === page ? "bg-btn01 text-white" : "bg-white text-btn01 border border-btn01"}`}
              >
                {page}
              </button>
            ))}
            {currentPage < maxPage - 2 && maxPage >= 6 && (
              <>
                {currentPage < maxPage - 2 && <span className="mr-3">...</span>}
                <button
                  onClick={() => handlePageChange(maxPage)}
                  className={`font-medium font-poppins w-10 h-10 flex justify-center items-center rounded mr-3 cursor-pointer ${currentPage === maxPage ? "bg-btn01 text-white" : "bg-white text-btn01 border border-btn01"}`}
                >
                  {maxPage}
                </button>
              </>
            )}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === maxPage}
              className="w-10 h-10 bg-white flex justify-center items-center rounded mr-3 cursor-pointer"
            >
              <img alt="arrowright" src={arrowright} className="h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataManagementPage;
