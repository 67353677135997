import React, { useState, useEffect } from "react";
import CustomLogin from "../components/CustomLogin";
import ManagersLogin from "../components/ManagersLogin";

function Home({setIsModalOpen}) {
  const [active, setActive] = useState(true);
  const [selectedIdentity, setSelectedIdentity] = useState("旅客");

  const handleSelectIdentity = (identity) => {
    setSelectedIdentity(identity);
  };

  const handleRoleChange = () => {
    setActive(true);
    setSelectedIdentity("旅客");
  };

  useEffect(() => {
    localStorage.setItem("role", selectedIdentity);
  }, [selectedIdentity]);

  return (
    <div className="flex justify-center items-center w-full min-h-screen max-h-max px-4 py-6 plane bg-center bg-no-repeat bg-cover">
      <div className="w-full sm:w-[539px] xsm:h-[603px] relative mt-[60px]">
        <div className="flex">
          <div
            className={`w-7/12 sm:w-[300px] h-[61px] left-mark absolute flex flex-col justify-center items-center top-[-60px] cursor-pointer bg-white ${active ? "z-20" : "z-0 pt-7"}`}
            onClick={handleRoleChange}
          >
            <p
              className={`text-base xsm:text-xl flex relative ${active ? "gradient-underline pb-2" : "mr-14 mt-5"}`}
            >
              旅客
            </p>
            <div
              className={`${active ? "hidden" : "flex"} w-full p-6 bg-white h-[20px] ${active ? "z-20" : "z-0"}`}
            >
              .
            </div>
          </div>
          <div
            className={`w-7/12 xsm:w-[300px] h-[61px] right-mark absolute flex flex-col justify-center items-center right-0 top-[-60px] cursor-pointer bg-white ${active ? "z-0 pt-7" : "z-20"}`}
            onClick={() => setActive(false)}
          >
            <p
              className={`text-base xsm:text-xl flex relative pb-2 ${active ? "mt-5 ml-14" : "gradient-underline"}`}
            >
              管理人員
            </p>
            <div
              className={`${active ? "flex" : "hidden"} w-full p-6 bg-white h-[20px] ${active ? "z-0" : "z-20"}`}
            >
              .
            </div>
          </div>
        </div>
        <div
          className="w-full sm:w-[539px] xsm:h-[603px] bg-white bottom-mark relative z-10"
          style={{
            borderRadius: active
              ? "0% 5% 5% 5% / 0% 5% 5% 5%"
              : "5% 0% 5% 5% / 5% 0% 5% 5%",
          }}
        >
          {active ? (
            <CustomLogin setIsModalOpen={setIsModalOpen}/>
          ) : selectedIdentity === "旅客" ? (
            <ManagersLogin onSelectIdentity={handleSelectIdentity} />
          ) : (
            <CustomLogin setIsModalOpen={setIsModalOpen}/>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
