import axios from "axios";
import apiBaseURL from "../../config";

const apiClient = axios.create({
  baseURL: apiBaseURL,
  paramsSerializer: (params) => {
    // 手動序列化參數
    return Object.keys(params)
      .map((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          // 這裡處理陣列，將它們展開為單獨的 key=value&key=value 格式
          return value.map((v) => `${key}=${encodeURIComponent(v)}`).join("&");
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&");
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const getAdminApplications = (
  isCompleted,
  search,
  start_date,
  end_date,
  page = 1,
  pageSize = 8
) => {
  const params = {
    page,
    page_size: pageSize,
  };

  if (isCompleted !== undefined) {
    params.is_completed = isCompleted;
  }

  if (search) {
    params.search = search;
  }

  if (start_date) {
    params.start_date = start_date;
  }

  if (end_date) {
    params.end_date = end_date;
  }

  return apiClient.get("/v1/admin/applications", { params });
};

const getApplicationExcel = (isCompleted, search, start_date, end_date) => {
  const params = {};

  if (isCompleted !== undefined) {
    params.is_completed = isCompleted;
  }

  if (search) {
    params.search = search;
  }

  if (start_date) {
    params.start_date = start_date;
  }

  if (end_date) {
    params.end_date = end_date;
  }

  return apiClient.get("/v1/admin/applications/excel", {
    params,
    responseType: "blob",
  });
};

const getApplicationDetail = (applicationId) => {
  return apiClient.get(`/v1/admin/applications/${applicationId}`);
};

const approveApplications = (applicationId) => {
  return apiClient.patch(`/v1/admin/applications/${applicationId}/approve`);
};

const getCouriers = (cities = [], orderBy = "desc", page = 1, pageSize = 8) => {
  const params = {
    page,
    page_size: pageSize,
    cities,
    order_by: orderBy,
  };

  return apiClient.get("/v1/admin/couriers", { params });
};

const updatePickup = (applicationId, pickupPersonId) => {
  return apiClient.patch(
    `/v1/admin/applications/${applicationId}/pickup_person`,
    {
      pickup_person_id: pickupPersonId,
    }
  );
};

const notifyCompletion = (applicationId) => {
  return apiClient.post(
    `/v1/admin/applications/${applicationId}/notify_completion`
  );
};

const updateDelivery = (applicationId, deliveryPersonId) => {
  return apiClient.patch(
    `/v1/admin/applications/${applicationId}/delivery_person`,
    {
      delivery_person_id: deliveryPersonId,
    }
  );
};

const approveRemittance = (applicationId) => {
  return apiClient.patch(
    `/v1/admin/applications/${applicationId}/remittance_approve`
  );
};

const getCities = () => {
  return apiClient.get("/v1/cities");
};

export {
  apiClient as default,
  getAdminApplications,
  getApplicationExcel,
  getApplicationDetail,
  approveApplications,
  getCouriers,
  updatePickup,
  notifyCompletion,
  updateDelivery,
  approveRemittance,
  getCities,
};
