import React, { useState, useEffect } from "react";
import PassportQuestion from "./PassportQuestion";
import CompatriotQuestion from "../compation/CompatriotQuestion";
import plus from "../../image/plus.svg";
import remove from "../../image/delete.svg";

function PassportFormData({ onFormDataChange }) {
  const today = new Date().toISOString().slice(0, 10); // 當前日期
  const [forms, setForms] = useState([
    {
      index: 0,
      type: "passport", // 新增type來區分表單類型
      files: [],
      formDate: today,
      idNumber: "",
      birthDate: null,
      birthDateInput: "",
      age: "",
      applicantName: "",
      applicantPhone: "",
      foreignFirstName: "",
      foreignLastName: "",
      alias: "",
      delivery_option: null,
      selectEnglish: null,
      changeEnglish: null,
      changeAlias: null,
      isDatePickerOpen: false,
      applicationCategory: "",
      is_alias_same_as_old_passport: false,
      is_delete_alias: false,
      add_alias: null,
      is_foreign_name_same_as_old_passport: false,
      is_update_foreign_name: false,
      update_alias: null,
      checkedStates: {
        need_two_inch_photo: false,
        need_ID: false,
        need_old_passport: false,
        need_household_registry: false,
        need_legal_representative: false,
        need_resident_certificate: false,
        need_lost_report: false,
        need_alias_proof_document: false,
        need_other_document: false,
    },
    },
  ]);

  useEffect(() => {
    onFormDataChange(forms);
  }, [forms,onFormDataChange]);
  
  
  

  const handleAddCompatriotApplication = () => {
    if (forms.length >= 10) return;
    setForms((prevForms) => [
      ...prevForms,
      {
        index: prevForms.length,
        type: "compatriot", // 設置類型為台胞證
        files: [],
        formDate: "",
        idNumber: "",
        birthDate: null,
        birthDateInput: "",
        applicantName: "",
        applicantPhone: "",
        isDatePickerOpen: false,
      },
    ]);
  };

  const handleAddFamilyApplication = () => {
    if (forms.length >= 10) return;
    setForms((prevForms) => [
      ...prevForms,
      {
        index: prevForms.length,
        type: "passport",
        files: [],
        formDate: today,
        idNumber: "",
        birthDate: null,
        birthDateInput: "",
        age: "",
        applicantName: "",
        applicantPhone: "",
        foreignFirstName: "",
        foreignLastName: "",
        alias: "",
        delivery_option: null,
        selectEnglish: null,
        changeEnglish: null,
        changeAlias: null,
        isDatePickerOpen: false,
        applicationCategory: "",
        is_alias_same_as_old_passport: false,
        is_delete_alias: false,
        add_alias: "",
        is_foreign_name_same_as_old_passport: false,
        is_update_foreign_name: false,
        update_alias: "",
        checkedStates: {
          need_two_inch_photo: false,
          need_ID: false,
          need_old_passport: false,
          need_household_registry: false,
          need_legal_representative: false,
          need_resident_certificate: false,
          need_lost_report: false,
          need_alias_proof_document: false,
          need_other_document: false,
        },
      },
    ]);
  };
  
  

  const handleRemoveForm = (index) => {
    setForms((prevForms) => prevForms.filter((form) => form.index !== index));
  };

  return (
    <div className=" min-w-[288px] max-w-[954px] w-full">
      {forms.map((passportForm) => (
        <div key={passportForm.index} className="relative">
          {passportForm.type === "passport" ? (
            <PassportQuestion
              form={passportForm}
              formIndex={passportForm.index}
              forms={forms}
              setForms={setForms}
            />
          ) : (
            <CompatriotQuestion
            setForms={setForms} 
            formIndex={passportForm.index} 
            passportIdNumber={forms.find(f => f.type === "passport" && f.idNumber)?.idNumber}
          />
          )}
          {forms.length > 1 && passportForm.index !== 0 && (
            <button
              className="absolute top-2 right-2"
              onClick={() => handleRemoveForm(passportForm.index)}
            >
              <img src={remove} alt="移除表單" className="w-[24px] h-[24px]" />
            </button>
          )}
        </div>
      ))}
      <div className="flex flex-col items-center gap-y-[24px] mb-[40px]">
        <button
          className="border-2 border-black w-[256px] sm:w-[400px] h-[44px] rounded-lg flex items-center justify-center gap-x-2"
          onClick={handleAddCompatriotApplication}
        >
          <img src={plus} alt="" className="w-[24px] h-[24px]" />
          一起申請台胞證
        </button>
        <button
          className="border-2 border-black w-[256px] sm:w-[400px] h-[44px] rounded-lg flex items-center justify-center gap-x-2"
          onClick={handleAddFamilyApplication}
        >
          <img src={plus} alt="" className="w-[24px] h-[24px]" />
          幫家人申請
        </button>
      </div>
    </div>
  );
}

export default PassportFormData;
