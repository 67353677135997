import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";
import axios from "axios";
import spaling from '../../image/sapling.svg'

function WaitingDelivery({application_id}) {
  const [qrCode, setQrCode] = useState(null);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
  
    const fetchQrCode = async () => {
      try {
        const qrCodeResponse = await axios.post(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/qr_code`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (qrCodeResponse.status === 200) {
          setQrCode(qrCodeResponse.data.data.qr_code); // 假設後端返回的QR Code 是Base64格式的圖片
        } else {
          console.error("Failed to fetch QR code");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    const fetchDeliveryInfo = async () => {
      try {
        const response = await axios.get(
          `${apiBaseURL}/v1/traveler/applications/${application_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (response.status === 200) {
          setResponseData(response.data.data); // 將整個回應資料保存
        } else {
          console.error("Failed to fetch delivery info");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    fetchDeliveryInfo();
    fetchQrCode();
  }, [application_id]);
  

  return (
    <div className="flex flex-col h-[362px] justify-between items-center h-full">
      {/* 地址 */}
      <div className="p-6 w-full flex flex-col  rounded-xl border shadow-xl flex flex-col gap-y-2 mb-[50px]">
        <div className="grid sm:grid-cols-2 grid-rows-2 sm:grid-rows-none gap-y-2">
          <span className="font-bold text-[14px] sm:text-[18px]">收件時間: 
          <span className="text-[14px] sm:text-[18px] font-normal pl-2">{responseData?.delivery_date}</span>
          </span>
          <span className="font-bold text-[14px] sm:text-[18px]">收件時段: 
          <span className="text-[14px] sm:text-[18px] font-normal pl-2">{responseData?.delivery_time}</span>
          </span>
        </div>
        <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
          主要申辦人:
          <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
          {responseData?.applications[0].applicant_name}
          </span>
        </span>
        <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
          收件地址:
          <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
            {responseData?.delivery_area}{responseData?.delivery_address}
          </span>
        </span>
      </div>
      {/* 文案 */}
      <div className="flex flex-col items-center">
        <span className="font-bold text-[20px] sm:text-[24px] mb-[50px]">等待回件中</span>
        <span className="text-[14px] text-center sm:text-base md:text-[18px] mb-[40px]">當收件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速收件人員讀取訂單。</span>
      </div>
      {/* QRCode */}
      <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
        {qrCode ? (
          <img src={qrCode} alt="QR Code" />
        ) : (
          <span>加載中...</span>
        )}
      </div>
      {/* 碳排量 */}
      <div className="w-full justify-center items-center flex flex-row gap-x-2 mt-[50px]">
        <img src={spaling} alt="" className="w-6 h-6" />
        <span className="text-[14px] sm:text-[18px] text-[#71AC4D]">恭喜你使用此系統總共減少{responseData?.CO2_emission_reduction}kg CO2e</span>
      </div>
    </div>
  );
}

export default WaitingDelivery;
