import React, { useRef, useState, useCallback } from "react";
import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import upload from "../../image/upload.svg";
import first from "../../image/first.svg";
import second from "../../image/second.svg";
import date from "../../image/datepicker.svg";
import close from "../../image/cross.svg";
import idcard from "../../image/idcard.svg";
import PassportDetail from "./QuestionDetail";
import Loading from "../Loading";

function PassportQuestion({ form, formIndex, forms, setForms }) {
  const fileInputRefs = useRef([]);
  const [showHint, setShowHint] = useState(false);
  const [hintMessage, setHintMessage] = useState("");
  const [hintTitle, setHintTitle] = useState("");
  const [isLoading,setIsLoading] = useState(false)
  const today = new Date();
  const minguoYear = (today.getFullYear() - 1911).toString().padStart(3, "0");
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const todayMinguoDate = `${minguoYear}-${month}-${day}`;

  const handleFileChange = useCallback(
    async (event, index) => {
      const file = event.target.files[0];
      if (file) {
        const validFormats = [
          "image/jpeg",
          "image/png",
          "image/heif",
          "image/heic",
          "image/gif",
        ];
        if (!validFormats.includes(file.type)) {
          setHintTitle("檔案格式錯誤");
          setHintMessage(
            "請確認上傳的檔案是否為以下格式 :  .jpeg、.png、.HEIF、.HEIC、.gif"
          );
          setShowHint(true);
          return;
        }

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        let timeoutId = setTimeout(() => {
          setIsLoading(true);
        }, 500); // 設置0.5秒的延遲
        try {
          const compressedFile = await imageCompression(file, options);

          const fileNumber = forms[formIndex].files.length + 1;
          const newFileName = `${forms[formIndex].idNumber}_P_${fileNumber}.${compressedFile.type.split('/')[1]}`;

          setForms((prevForms) =>
            prevForms.map((form, i) =>
              i === formIndex
                ? {
                    ...form,
                    files: [
                      ...form.files,
                      {
                        name: compressedFile.name,
                        size: compressedFile.size,
                        customName: newFileName,
                        compressedFile,
                        type: compressedFile.type, 
                      },
                    ],
                  }
                : form
            )
          );
          clearTimeout(timeoutId);
        } catch (error) {
          console.error("壓縮圖片時出錯:", error);
          clearTimeout(timeoutId);
        }finally{
          setIsLoading(false)
        }
      }
    },
    [forms, setForms, formIndex]
  );

  const handleInputChange = useCallback((event, index) => {
    const inputDate = event.target.value;
  
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i === index) {
          const updatedForm = {
            ...form,
            birthDateInput: inputDate, // 顯示民國格式
          };
  
          if (/^\d{3}-\d{2}-\d{2}$/.test(inputDate)) { 
            const [year, month, day] = inputDate.split("-");
            const gregorianYear = parseInt(year, 10) + 1911;
            const formattedDate = new Date(`${gregorianYear}-${month}-${day}`);
            if (!isNaN(formattedDate)) {
              updatedForm.birthDate = formattedDate; // 保留西元格式
              updatedForm.age = calculateAge(formattedDate); 
            } else {
              updatedForm.age = ""; 
            }
          } else {
            updatedForm.age = "";
          }
  
          return updateCheckedStates(updatedForm); 
        }
        return form;
      })
    );
  }, [setForms]);
  
// 確保在 handleDateSelect 中正確設置 birthDate 和 birthDateInput
const handleDateSelect = useCallback((date, index) => {
  setForms((prevForms) =>
    prevForms.map((form, i) => {
      if (i === index) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        const updatedForm = {
          ...form,
          birthDate: formattedDate, // 確保這裡保存的是 YYYY-MM-DD
          birthDateInput: formatToMinguoYear(date), // 這裡使用民國年格式
          age: calculateAge(date),
          isDatePickerOpen: false, 
        };
        return updateCheckedStates(updatedForm);
      }
      return form;
    })
  );
}, [setForms]);

  
  
  const handleNormalClick = useCallback((value, index) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i !== index) return form;
        const newForm = { ...form, delivery_option: value };
        return updateCheckedStates(newForm);
      })
    );
  }, [setForms]);

  const handleFirstClick = useCallback((value, index) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i !== index) return form;

        const newForm = {
          ...form,
          selectFirst: value,
          is_alias_same_as_old_passport:value === "change",
          foreignFirstName: "",
          applicationCategory: value,
          foreignLastName: "",
          alias: "",
          selectEnglish: "none",
          changeEnglish: "old",
          changeAlias: "sameAsOld",
        };
        return updateCheckedStates(newForm);
      })
    );
  }, [setForms]);


  const handleEnglishClick = useCallback((value, index) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i !== index) return form;

        const newForm = {
          ...form,
          selectEnglish: value,
          is_foreign_name_same_as_old_passport: value === "old", // 當選擇同舊照時設置為 true
        };
        return updateCheckedStates(newForm);
      })
    );
  }, [setForms]);


  const handleChangeEnglishClick = useCallback((value, index) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i !== index) return form;
  
        const resetFields = value === "change" 
          ? { foreignFirstName: "", foreignLastName: "", is_update_foreign_name: true }
          : { is_update_foreign_name: false };
  
        const newForm = {
          ...form,
          changeEnglish: value,
          ...resetFields, 
        };
        return updateCheckedStates(newForm);
      })
    );
  }, [setForms]);

  const handleAliasClick = useCallback((value, index) => {
    setForms((prevForms) =>
      prevForms.map((form, i) => {
        if (i !== index) return form;

        const newForm = {
          ...form,
          changeAlias: value,
          alias: value === "addAlias" || value === "modifyAlias" ? "" : null, // 當選擇新增或變更時設為空字串，其他情況設為 null
          add_alias: value === "addAlias" || value === "modifyAlias" ? "" : null, 
          is_delete_alias: value === "removeAlias", 
          update_alias: value === "addAlias" || value === "modifyAlias" ? "" : null,
        };
        if (value === "sameAsOld" || value === "removeAlias") {
          newForm.alias = null;
        }
        return updateCheckedStates(newForm);
      })
    );
  }, [setForms]);

  
  
  const handleClick = useCallback(
    (index) => {
      if (forms[index].idNumber.trim() === "") {
        setHintTitle("請先輸入身分證 !!");
        setHintMessage("");
        setShowHint(true);
      } else {
        setShowHint(false);
        if (fileInputRefs.current[index]) {
          fileInputRefs.current[index].click();
        }
      }
    },
    [forms]
  );

  const calculateAge = (birthDate) => {
    if (!birthDate) return "";
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatToMinguoYear = (date) => {
    if (!date) return "";
    const year = (date.getFullYear() - 1911).toString().padStart(3, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const updateCheckedStates = (form) => {
    console.log("Current age:", form.age, typeof form.age);

    const newCheckedStates = {
      need_two_inch_photo: false,
      need_ID: false,
      need_old_passport: false,
      need_household_registry: false,
      need_legal_representative: false,
      need_resident_certificate: false,
      need_lost_report: false,
      need_alias_proof_document: false,
      need_other_document: false,
    };

    if (form.age >= 18 && form.selectFirst === "FIRST_TIME") {
      newCheckedStates.need_two_inch_photo = true;
      newCheckedStates.need_ID = true;
    }

    if (form.selectFirst === "RENEWAL") {
      newCheckedStates.need_two_inch_photo = true;
      newCheckedStates.need_ID = true;
      newCheckedStates.need_old_passport = true;
    }

    if (form.age < 18) {
      newCheckedStates.need_two_inch_photo = true;
      newCheckedStates.need_ID = true;
      newCheckedStates.need_household_registry = true;
      newCheckedStates.need_legal_representative = true;
    }

    if (form.changeEnglish === "change") {
      newCheckedStates.need_alias_proof_document = true;
    }

    if (
      form.changeAlias === "addAlias" ||
      form.changeAlias === "modifyAlias"
    ) {
      newCheckedStates.need_two_inch_photo = true;
      newCheckedStates.need_ID = true;
      newCheckedStates.need_alias_proof_document = true;
    }

    if (form.selectFirst === "REPLACEMENT") {
      newCheckedStates.need_two_inch_photo = true;
      newCheckedStates.need_ID = true;
      newCheckedStates.need_lost_report = true;
    }

    if (form.selectFirst === "FIRST_TIME" && form.selectEnglish === "have") {
      newCheckedStates.need_alias_proof_document = true;
    }

if (form.age === null || form.age === undefined || form.age === "") {
  newCheckedStates.need_legal_representative = false;
  newCheckedStates.need_household_registry = false;
}


    return { ...form, checkedStates: newCheckedStates };
  };


  const normalOptions = [
    { label: "一般件", value: "REGULAR" },
    { label: "速件", value: "EXPRESS" },
  ];

  const passportOptions = [
    { label: "首次申請護照", value: "FIRST_TIME" },
    { label: "護照換發", value: "RENEWAL" },
    { label: "遺失補發", value: "REPLACEMENT" },
  ];

  const englishAliasOptions = [
    { label: "無外文別名", value: "none" },
    { label: "有外文別名", value: "have" },
  ];

  const changeEnglishName = [
    { label: "同舊照", value: "old" },
    { label: "變更外文姓名", value: "change" },
  ];

  const changeEnglishAlias = [
    { label: "同舊照", value: "sameAsOld" },
    { label: "新增外文別名", value: "addAlias" },
    { label: "刪除外文別名", value: "removeAlias" },
    { label: "變更外文別名", value: "modifyAlias" },
  ];

  return (
    <div
      className="bg-white px-4 sm:px-6 md:px-4 py-4 sm:py-6 grid gap-[24px]  shadow-md  rounded-xl mb-[24px]"
    >
      <div className="grid gap-y-[24px] ">
        <div className="flex items-center">
          <img src={first} alt="" />
          <span className="ml-[12px] text-base sm:text-[20px] md:text-[24px] font-bold">
            旅客背景資料
          </span>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[32px] gap-y-[24px]">
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">旅客姓名</span>
            <input
              type="text"
              placeholder="輸入旅客姓名"
              value={form.applicantName}
              onChange={(e) =>
                setForms((prevForms) =>
                  prevForms.map((f, i) =>
                    i === formIndex
                      ? { ...f, applicantName: e.target.value }
                      : f
                  )
                )
              }
              className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px] w-full"
            />
          </div>
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">填表日期</span>
            <input
              type="text"
              value={form.formDate}
              disabled
              className="border rounded-md bg-[#F7F7F9] p-[14px] h-[53px]"
            />
          </div>
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">身分證字號</span>
            <input
              type="text"
              placeholder="輸入身分證字號"
              value={form.idNumber}
              onChange={(e) =>
                setForms((prevForms) =>
                  prevForms.map((f, i) =>
                    i === formIndex ? { ...f, idNumber: e.target.value } : f
                  )
                )
              }
              className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px]"
            />
          </div>
          <div className="grid gap-y-[16px]">
            <div>
              <span className="font-bold text-base sm:text-[18px] mr-2">出生日期</span>
              <span className="text-[#EE1D52] text-base sm:text-[18px] hidden md:inline">
                民國出生日期(YYY-MM-DD)
              </span>
              <span className="text-[#EE1D52] text-[14px] sm:text-base md:hidden">
                民國出生日期(YYY-MM-DD)
              </span>
            </div>
            <div className="relative">
              <input
                type="text"
                value={form.birthDateInput}
                placeholder={todayMinguoDate}
                onChange={(e) => handleInputChange(e, formIndex)}
                className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px] w-full pr-10"
                readOnly
                onClick={() =>
                  setForms((prevForms) =>
                    prevForms.map((f, i) =>
                      i === formIndex
                        ? { ...f, isDatePickerOpen: !f.isDatePickerOpen }
                        : f
                    )
                  )
                }
              />
              <img
                src={date}
                alt="date"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() =>
                  setForms((prevForms) =>
                    prevForms.map((f, i) =>
                      i === formIndex
                        ? { ...f, isDatePickerOpen: !f.isDatePickerOpen }
                        : f
                    )
                  )
                }
              />
              {form.isDatePickerOpen && (
                <div className="absolute w-full flex justify-end z-10 right-0 mt-2">
                  <DatePicker
                    selected={form.birthDate}
                    onChange={(date) => handleDateSelect(date, formIndex)}
                    inline
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={new Date()}
                    locale={zhTW}
                    className="react-datepicker"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">連絡電話</span>
            <input
              type="text"
              placeholder="請輸入電話號碼"
              value={form.applicantPhone}
              onChange={(e) =>
                setForms((prevForms) =>
                  prevForms.map((f, i) =>
                    i === formIndex ? { ...f, applicantPhone: e.target.value } : f
                  )
                )
              }
              className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px]"
            />
          </div>
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">年齡</span>
            <input
              type="text"
              value={form.age}
              className="border rounded-md bg-[#F7F7F9] p-[14px] h-[53px]"
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="grid gap-y-[16px]">
          <span className="font-bold text-base sm:text-[18px]">送件類別</span>
          <div className=" grid-cols-2 grid sm:grid-cols-4  gap-x-[10px]">
            {normalOptions.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px] rounded-lg  text-center ${
                  form.delivery_option === option.value
                    ? "bg-orange text-white"
                    : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleNormalClick(option.value, formIndex)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        <div className="grid gap-y-[16px]">
          <span className="font-bold text-base sm:text-[18px]">申請護照類別</span>
          <div className="grid grid-row-3 sm:grid-cols-4 gap-y-[10px] gap-x-[10px]">
            {passportOptions.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px]   rounded-lg text-center ${
                  form.selectFirst === option.value
                    ? "bg-orange text-white"
                    : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleFirstClick(option.value, formIndex)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>

        <PassportDetail
          selectFirst={form.selectFirst}
          englishAliasOptions={englishAliasOptions}
          selectEnglish={form.selectEnglish}
          handleEnglishClick={(value) => handleEnglishClick(value, formIndex)}
          changeEnglishName={changeEnglishName}
          changeEnglish={form.changeEnglish}
          handleChangeEnglishClick={(value) =>
            handleChangeEnglishClick(value, formIndex)
          }
          changeAlias={form.changeAlias}
          changeEnglishAlias={changeEnglishAlias}
          handleAliasClick={(value) => handleAliasClick(value, formIndex)}
          checkedStates={form.checkedStates}
          files={form.files || []}
          handleClick={() => handleClick(formIndex)}
          fileInputRef={(el) => (fileInputRefs.current[formIndex] = el)}
          handleFileChange={(e) => handleFileChange(e, formIndex)}
          showHint={showHint}
          hintTitle={hintTitle}
          hintMessage={hintMessage}
          setShowHint={setShowHint}
          setFiles={(newFiles) =>
            setForms((prevForms) =>
              prevForms.map((f, i) =>
                i === formIndex ? { ...f, files: newFiles || [] } : f
              )
            )
          }
          idcard={idcard}
          close={close}
          upload={upload}
          second={second}
          setForms={setForms}
          index={formIndex}
          forms={forms}
        />
      </div>
      {isLoading && <Loading/>}
    </div>
  );
}

export default PassportQuestion;
