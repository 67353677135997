import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiBaseURL from "../config";

function ForgetPassword({ onEmailSent }) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    if (!email) return; // 检查邮箱是否为空

    try {
      const response = await axios.post(`${apiBaseURL}/v1/user/send_reset_verification_code`, {
        email,
      });
      const data = response.data;

      if (response.status === 200) {
        // 处理成功逻辑，例如显示成功信息
        setErrorMessage("验证码已发送，请检查您的邮箱。");
        onEmailSent(email);
      } else {
        // 处理错误，显示错误信息
        setErrorMessage(data.message || "发送验证码失败");
      }
    } catch (error) {
      setErrorMessage("网络错误，请稍后再试。");
    }
  };

  return (
    <div className="w-full grid gap-y-[24px] px-4 xsm:px-8 pt-[50px] xsm:h-[540px] relative">
      <div>
        <p className="text-xl xsm:text-[28px] font-semibold text-[#4DA8C5]">
          忘記密碼{" "}
        </p>
        <p className="text-sm xsm:text-base mt-1">
          我們將發送驗證碼至您的信箱。
        </p>
      </div>
      <div className="h-[107px]">
        <p className="text-base xsm:text-lg mb-1 h-[27px]">帳號</p>
        <input
          className={`w-full h-[52px] pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"} text-base focus:border-[1px] focus:border-[#4DA8C5] focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-[#545454]`}
          placeholder="請輸入帳號或Email"
          type="text"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <button
        className={`rounded-full h-[56px] w-full mt-[58px] xsm:mt-0 py-4 text-base xsm:text-lg font-medium ${
          email
            ? "bg-[#4DA8C5] hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white"
            : "bg-[#DEDEDE] text-white cursor-not-allowed"
        }`}
        disabled={!email}
        onClick={handleSubmit}
      >
        取得驗證碼
      </button>
      <div className="flex justify-center py-7">
        <p className="text-base mr-1 xsm:pt-0">取消重設密碼？</p>
        <Link to="/">
          <button className="text-[#4DA8C5] text-base font-bold underline underline-offset-2">
            返回登入
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ForgetPassword;
