import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";
import axios from 'axios';
import spaling from '../../image/sapling.svg'

function WaitingPickup({ isOfflinePayment, application_id,payment_method }) {
  const [qrCode, setQrCode] = useState(null);
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
  
    const fetchQrCode = async () => {
      try {
        const qrCodeResponse = await axios.post(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/qr_code`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (qrCodeResponse.status === 200) {
          setQrCode(qrCodeResponse.data.data.qr_code);
        } else {
          console.error("Failed to fetch QR code");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    const fetchPaymentInfo = async () => {
      try {
        const paymentResponse = await axios.get(
          `${apiBaseURL}/v1/traveler/applications/${application_id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
    
        if (paymentResponse.status === 200) {
          setReportData(paymentResponse.data.data); // 更新資料結構
        } else {
          console.error("Failed to fetch payment info");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    
  
    fetchQrCode();
    fetchPaymentInfo();

  }, [application_id, isOfflinePayment, payment_method]);
  

  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: '一般件',
      EXPRESS: '速件',
    };
    return translationMap[type] || type;
  };

  return (
    <>
        <div className="w-full flex flex-col gap-y-[50px]">
          {/* 詳情 */}
          <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
          {reportData ? (
            <div>
              <div className="w-full flex flex-col gap-y-[8px]">
                <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                  申請時間:
                  <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                    {reportData?.applications[0].application_date}
                  </span>
                </span>
                {reportData?.applications?.map((application, index) => (
                   <div key={application.application_detail_id} className="flex flex-col gap-y-2">
                    {index !== 0 && <hr className="border-[#A3A3A3]" />}
                     <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                       {index === 0 ? "主要申辦人:" : "申辦人:"}
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                        {application.applicant_name}
                       </span>
                     </span>
                     <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦證件: 
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{application.application_type}</span>
                     </span>
                     <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總件數: 
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{application.quantity}</span>
                     </span>
                     <div className="flex justify-between w-full">
                       <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦類別: 
                         <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{translateProcessType(application.process_type)}</span>
                       </span>
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal">{application.unit_price}</span>
                     </div>
                   </div>
                 ))}
              </div>
              <hr className=" border-[#A3A3A3] mt-2" />
              <div className="flex justify-between w-full">
                <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總計: </span>
                <span className="text-[14px] sm:text-base md:text-[18px] font-normal text-[#EE1D52]">
                  ${reportData?.total_amount}
                </span>
              </div>
            </div>
            ) : (
              <div>加載中...</div>
            )}
          </div>
          {/* 地址 */}
          <div className="p-6 w-full flex flex-col  rounded-xl border shadow-xl flex flex-col gap-y-2">
            <div className="grid sm:grid-cols-2 grid-rows-2 sm:grid-rows-none gap-y-2">
              <span className="font-bold text-[14px] sm:text-[18px]">收件時間: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.pickup_date}</span>
              </span>
              <span className="font-bold text-[14px] sm:text-[18px]">收件時段: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.pickup_time}</span>
              </span>
            </div>
            <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
              主要申辦人:
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                {reportData?.applications?.[0]?.applicant_name}
              </span>
            </span>
            <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
              收件地址:
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                {reportData?.pickup_area}{reportData?.pickup_address}
              </span>
            </span>
          </div>
          {/* 訂單狀況 */}
          {/* <div className="p-6 w-full flex flex-col  rounded-xl border shadow-xl flex flex-col gap-y-2">
            <div className="flex justify-between w-full">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">訂單批准狀態: </span>
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                {reportData?.is_approved ? '已批准' : '未批准'}
              </span>
            </div>
          </div> */}
          {/* 文案 */}
          <div className="flex flex-col gap-y-[30px] justify-between items-center">
            <div className="flex flex-col items-center gap-y-[30px]">
              <span className="font-bold text-[20px] sm:text-[24px]">待付款</span>
              <span className="text-[14px] sm:text-base text-center ">當收件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速收件人員讀取訂單。</span>
            </div>
            <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
              {qrCode ? <img src={qrCode} alt="QR Code" /> : <div>加載中...</div>}
            </div>
            <div className="flex flex-col items-center text-center text-base sm:text-[24px] mb-10">
              <span className="leading-[1.4]">您的案件已送出，將於收件時收款。<br />金額：<span className="text-[#EE1D52]">{reportData?.total_amount}</span>元，訂單編號為：<span className="text-[#EE1D52]">{application_id}</span>，請保留此QR code截圖。</span>
            </div>
          </div>
          {/* 碳排量 */}
          <div className="w-full justify-center items-center flex flex-row gap-x-2">
            <img src={spaling} alt="" className="w-6 h-6" />
            <span className="text-[14px] sm:text-[18px] text-[#71AC4D]">恭喜你使用此系統總共減少{reportData?.CO2_emission_reduction}kg CO2e</span>
          </div>
        </div>

        {/* <div className="flex flex-col h-[362px] justify-between items-center mb-[100px]">
          <div className="flex flex-col items-center sm:gap-y-[50px] gap-y-2">
            <span className="font-bold text-[20px] sm:text-[24px]">等待取件中</span>
            <span className="text-[14px] sm:text-base text-center md:text-[18px] mb-[40px]">當取件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速取件人員讀取訂單。</span>
          </div>
          <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
            <img src={qrCode} alt="QR Code" />
          </div>
        </div> */}
    </>
  );
}

export default WaitingPickup;
