import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";
import spaling from '../../image/sapling.svg'

function Completed({application_id}) {
  const [responseData, setResponseData] = useState(null);


  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: "一般件",
      EXPRESS: "速件",
    };
    return translationMap[type] || type;
  };

  const translatePaymentMethod = (method) => {
    const translationMap = {
      // ONLINE: "線上支付",
      // REMITTANCE: "匯款",
      OFFLINE:"線下支付",
      PAID:"已付款"
    };
    return translationMap[method] || method;
  };
  

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("access_token");
  
      const applicationResponse = await fetch(`${apiBaseURL}/v1/traveler/applications/${application_id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const applicationData = await applicationResponse.json();
      setResponseData(applicationData?.data); // 新增的部分
    };
  
    fetchData();
  }, [application_id]);

  return (
    <div className="w-full flex flex-col items-center gap-y-[50px] ">
      <div>
        <span className="font-bold text-[24px] mb-[50px]">已完成</span>
      </div>
      <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
        {responseData ? (
          <div>
            <div className="flex flex-col gap-y-2">
              <div className="w-full grid sm:grid-cols-2 gap-y-2 justify-between">
                <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                  申請時間:
                  <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                    {responseData?.applications[0].application_date}
                  </span>
                </span>
                <div>
                  <span className="font-bold text-[14px] sm:text-base md:text-[18px]">聯絡人:</span>
                  <span className="text-[14px] sm:text-base md:text-lg font-normal pl-2">{responseData?.applications[0].applicant_name}</span>
                </div>
              </div>
              <div className="w-full grid sm:grid-cols-2 gap-y-2 justify-between">
                <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                  付款方式:
                  <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                    {translatePaymentMethod(responseData?.payment_method)}
                  </span>
                </span>
                <div>
                  <span className="font-bold text-[14px] sm:text-base md:text-[18px]">付款狀態:</span>
                  <span className="text-[14px] sm:text-base md:text-lg font-normal pl-2">{translatePaymentMethod(responseData?.payment_status)}</span>
                </div>
              </div>
              <hr className="border-[#A3A3A3] mb-2" />
            </div>
            <div className="w-full flex flex-col gap-y-[8px]">
              {responseData?.applications?.map((application, index) => (
                <div key={application.application_detail_id} className="flex flex-col gap-y-2">
                  {index !== 0 && <hr className="border-[#A3A3A3]" />}
                  <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                    申辦人
                    <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                      {application.applicant_name}
                    </span>
                  </span>
                  <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦證件: 
                    <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{application.application_type}</span>
                  </span>
                  <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總件數: 
                    <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{application.quantity}</span>
                  </span>
                  <div className="flex justify-between w-full">
                    <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦類別: 
                      <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{translateProcessType(application.process_type)}</span>
                    </span>
                    <span className="text-[14px] sm:text-base md:text-[18px] font-normal">${application.unit_price}</span>
                  </div>
                </div>
              ))}
            </div>
            <hr className="border-[#A3A3A3] my-2" />
            <div className="flex justify-between w-full">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總計: </span>
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal text-[#EE1D52]">
                ${responseData?.total_amount}
              </span>
            </div>
          </div>
        ) : (
          <div>加載中...</div>
        )}
      </div>
      <div className="bg-white w-full h-[96px] sm:h-[73px] grid grid-rows-2 sm:grid-cols-2 gap-y-2 p-[24px] border rounded-xl shadow-xl">
        <div className="flex">
          <div className="font-bold text-[14px] sm:text-[18px]">申辦狀態：</div>
          <div className="ml-2 text-[14px] sm:text-base">已完成</div>
        </div>
        <div className="flex">
          <div className="font-bold text-[14px] sm:text-[18px]">回件狀態：</div>
          <div className="ml-2 text-[14px] sm:text-base">已完成</div>
        </div>
      </div>
      {/* 碳排量 */}
      <div className="w-full justify-center items-center flex flex-row gap-x-2">
        <img src={spaling} alt="" className="w-6 h-6" />
        <span className="text-[14px] sm:text-[18px] text-[#71AC4D]">恭喜你使用此系統總共減少{responseData?.CO2_emission_reduction}kg CO2e</span>
      </div>
    </div>
  );
}

export default Completed;
