import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useApplicationDetail,
  useApproveApplication,
  useNotifyCompletion,
  useApproveRemittance,
} from "../hook/useApi";
import {
  getCategoryText,
  getPaymentMethodText,
  getPaymentStatusText,
} from "../component/TextConverters";
import idcard from "../images/idcard.svg";
import check from "../images/check.svg";
import arrowleft from "../images/arrowleft.svg";
import backhome from "../images/backhome_black.svg";
import cross from "../images/cross.svg";

function ApplicationDetailPage() {
  const { application_id } = useParams();
  const { data, isLoading: isDetailLoading } =
    useApplicationDetail(application_id);
  const { mutate: approveApplication, isLoading: isApproving } =
    useApproveApplication();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleApprove = (applicationId) => {
    setIsUpdating(true);
    approveApplication(applicationId, {
      onSuccess: (data) => {
        console.log("Application approved:", data);
        setIsUpdating(false);
      },
      onError: (error) => {
        console.error("Error approving application:", error);
        setIsUpdating(false);
      },
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const { mutate: notifyCompletion, isLoading: isNotifying } =
    useNotifyCompletion();

  const handleNotifyCompletion = () => {
    notifyCompletion(application_id, {
      onSuccess: () => {
        console.log("Notification sent successfully");
      },
      onError: (error) => {
        console.error("Error sending notification:", error);
      },
    });
  };

  const { mutate: approveRemittance } = useApproveRemittance();
  const handleApproveRemittance = () => {
    approveRemittance(application_id, {
      onSuccess: (data) => {
        console.log("Remittance approved:", data);
      },
      onError: (error) => {
        console.error("Error approving remittance:", error);
      },
    });
  };

  return (
    <div className="bg-[#F7F7F9] w-full min-h-screen max-h-max">
      <div
        className={`flex w-full h-[52px] sm:h-[108px] z-30 sticky top-0 bg-white items-center justify-between py-6 px-4 sm:px-6 lg:px-16 shadow-md`}
      >
        <div className="flex w-auto h-10 justify-center">
          <Link to="/datamanagement">
            <div className="w-auto h-10 flex items-center cursor-pointer">
              <img alt="back" src={arrowleft} className="h-5 w-6 sm:h-6" />
              <p className="hidden sm:flex text-xl font-semibold text-black pl-2">
                返回
              </p>
            </div>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <p className="text-base sm:text-xl lg:text-2xl font-bold text-black font-roboto">
            Who's Out-
            <span className="font-bold text-base sm:text-xl md:hidden">
              後台
            </span>
          </p>
          <p className="hidden md:flex text-xl lg:text-2xl font-bold text-black font-roboto">
            收件/回件收款後台
          </p>
        </div>
        <div className="flex w-auto h-10 justify-center">
          <Link
            to="/datamanagement"
            className="w-auto sm:w-[86px] h-10 flex items-center cursor-pointer"
          >
            <p className="hidden sm:flex text-xl font-semibold text-black pr-[6px]">
              後台
            </p>
            <img alt="backhome" src={backhome} className="h-6" />
          </Link>
        </div>
      </div>
      {data && (
        <div className="flex flex-col items-center w-full px-4 sm:px-8 py-6">
          {data.applications.map((item) => (
            <div
              className="flex flex-col w-full items-center"
              key={item.ID_number}
            >
              <div className="w-full xl:w-[1200px] text-base sm:text-xl bg-white grid grid-cols-2 sm:grid-cols-4 gap-x-3 gap-y-1 sm:gap-y-6 pt-4 pb-2 sm:py-6 px-4 rounded-lg shadow-md">
                <div className="flex flex-col">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    編號
                  </div>
                  <div className="text-main-gray py-1 sm:py-2 px-2 sm:px-3">
                    {item.application_id}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    申請日期
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.application_date}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    姓名
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.applicant_name}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    辦理項目
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.application_type}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    身分證字號
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.ID_number}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    出生日期
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.applicant_birth_date}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    聯絡電話
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.applicant_phone}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    有無加辦臺胞證
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.is_applying_for_compatriot}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    護照申請類別
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {getCategoryText(item.passport_application_category)}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    外文姓氏
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.foreign_first_name || "無"}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    外文名字
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.foreign_last_name || "無"}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-main-gray text-white font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    台胞證申請類別
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {getCategoryText(item.compatriot_application_category)}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    外文別名
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.alias || "無"}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    新增別名
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.new_alias || "無"}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    變更別名
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.update_alias || "無"}
                  </div>
                </div>
                <div className="flex flex-col col-span-2 sm:col-span-1">
                  <div className="bg-[#F2F1E8] text-black font-semibold rounded-lg sm:rounded-xl py-1 px-2 sm:p-3">
                    刪除別名
                  </div>
                  <div className="text-main-gray  py-2 px-3">
                    {item.is_delete_alias || "無"}
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-[1200px] bg-white flex py-6 px-4 rounded-lg shadow-md my-8 sm:my-10">
                <div className="bg-[#F7F7F9] rounded-xl p-3 w-full">
                  <p className="text-main-gray text-base sm:text-xl font-semibold">
                    附件檔案
                  </p>
                  <p className="text-main-gray text-base sm:text-xl mt-3">
                    共{item.files.length}件
                  </p>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-3">
                    {item.files.map((file, index) => (
                      <div
                        className="bg-white border rounded-xl flex p-4 cursor-pointer"
                        key={index}
                        onClick={() => setSelectedFile(file)}
                      >
                        <img
                          alt="idcard"
                          src={file.url ? file.url : idcard}
                          className="w-10 h-10 object-contain"
                        />
                        <div className="flex flex-col ml-3 w-full">
                          <p className="w-[80%] font-semibold text-main-gray truncate text-base sm:text-base">
                            {file.name}
                          </p>
                          <p className="text-[#A3A3A3] text-[14px] sm:text-base">
                            {file.size}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedFile && (
                  <div
                    className="fixed inset-0 bg-black/60 z-30 flex justify-center items-center"
                    onClick={() => setSelectedFile(null)}
                  >
                    <div
                      className="w-full sm:w-auto h-auto p-14 my-6 mx-4 flex items-center justify-center relative"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        alt={selectedFile.name}
                        src={selectedFile.url}
                        className="max-w-full max-h-[85vh] object-contain"
                      />
                      <button
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        onClick={() => setSelectedFile(null)}
                      >
                        <img alt="cross" src={cross} className="w-8 h-8" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="text-main-gray text-base sm:text-xl font-semibold">
            是否批准申辦
          </div>
          {(() => {
            if (data.pickup_address === null) {
              return (
                <button className="bg-[#DEDEDE] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 cursor-not-allowed">
                  點此批准
                </button>
              );
            } else {
              return data.is_approved ? (
                <button className="bg-[#71AC4D] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 flex justify-center items-center cursor-default">
                  <img alt="check svg" src={check} className="w-6 h-6 mr-2" />
                  已批准
                </button>
              ) : (
                <button
                  className="bg-[#EE1D52] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 hover:bg-[#FFC5C5] hover:text-[#EE1D52]"
                  onClick={() => handleApprove(application_id)}
                >
                  {isDetailLoading || isApproving || isUpdating
                    ? "處理中..."
                    : "點此批准"}
                </button>
              );
            }
          })()}
          <div className="w-full xl:w-[1200px] bg-white text-base sm:text-xl grid grid-cols-6 gap-1 sm:gap-x-2 sm:gap-y-6 py-6 px-4 rounded-lg shadow-md mt-8 sm:mt-10">
            <div className="flex flex-col col-span-2">
              <div className="bg-[#F2F1E8] text-black font-semibold text-center rounded-l-xl p-2 sm:p-3">
                付款方式
              </div>
              <div className="text-main-gray text-center py-2 px-3">
                {getPaymentMethodText(data.payment_method)}
              </div>
            </div>
            <div className="flex flex-col col-span-2">
              <div className="bg-[#F2F1E8] text-black font-semibold text-center p-2 sm:p-3">
                總金額
              </div>
              <div className="text-main-gray text-center py-2 px-3">
                ${data.total_amount}
              </div>
            </div>
            <div className="flex flex-col col-span-2">
              <div className="bg-[#F2F1E8] text-black font-semibold text-center rounded-r-xl p-2 sm:p-3">
                付款狀態
              </div>
              <div className="text-main-gray text-center py-2 px-3">
                {getPaymentStatusText(data.payment_status)}
              </div>
            </div>
            {data.remittance_date && (
              <>
                <div className="flex flex-col col-span-3">
                  <div className="bg-[#F2F1E8] text-black font-semibold text-center rounded-l-xl p-2 sm:p-3">
                    匯款日期
                  </div>
                  <div className="text-main-gray text-center py-2 px-3">
                    {data.remittance_date}
                  </div>
                </div>
                <div className="flex flex-col col-span-3">
                  <div className="bg-[#F2F1E8] text-black font-semibold text-center rounded-r-xl p-2 sm:p-3">
                    匯款末5碼
                  </div>
                  <div className="text-main-gray text-center py-2 px-3">
                    {data.account_last_digits}
                  </div>
                </div>
              </>
            )}
            {data.payment_method && data.payment_method === "REMITTANCE" && (
              <div className="flex flex-col items-center col-span-6">
                <p className="text-main-gray text-base sm:text-xl font-semibold mt-10">
                  是否完成付款？
                </p>
                {data.payment_status === "PAID" ? (
                  <button className="bg-[#71AC4D] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 cursor-default flex justify-center items-center">
                    <img alt="check svg" src={check} className="w-6 h-6 mr-2" />
                    已完成付款
                  </button>
                ) : (
                  <button
                    className="bg-[#EE1D52] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 hover:bg-[#FFC5C5] hover:text-[#EE1D52]"
                    onClick={handleApproveRemittance}
                  >
                    點此確認已付款
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="w-full xl:w-[1200px] bg-white text-base sm:text-xl grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-4 py-6 px-4 rounded-lg shadow-md mt-8 sm:mt-10 items-center">
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">取件區域</p>
              <p className="text-main-gray mt-3">
                {data.pickup_area || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">取件地址</p>
              <p className="text-main-gray mt-3">
                {data.pickup_address || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">取件日期</p>
              <p className="text-main-gray mt-3">
                {data.pickup_date || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">取件時段</p>
              <p className="text-main-gray mt-3">
                {data.pickup_time || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3 col-span-1 sm:col-span-2">
              <p className="font-semibold text-main-gray">取件人員</p>
              <p className="text-main-gray mt-3">
                {data.pickup_person || "未選擇"}
              </p>
            </div>
            {(() => {
              if (
                !data.is_picked_up &&
                data.pickup_area !== "" &&
                data.pickup_address !== null &&
                data.pickup_date !== null &&
                data.pickup_time !== null
              ) {
                return (
                  <Link
                    to={`/choosepickup/${application_id}`}
                    className="flex justify-center items-center col-span-1 sm:col-span-2 mt-1 sm:mt-10"
                  >
                    <button className="bg-btn01 text-white font-semibold w-[460px] h-[50px] rounded-full hover:bg-[#66C0DD] active:bg-[#2F99BA]">
                      選擇取件人員
                    </button>
                  </Link>
                );
              } else if (data.is_approved && data.is_picked_up) {
                return (
                  <button className="bg-[#DEDEDE] text-white font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-10 mx-auto col-span-1 sm:col-span-2 cursor-not-allowed">
                    選擇取件人員
                  </button>
                );
              } else {
                return (
                  <button className="bg-[#DEDEDE] text-white font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-10 mx-auto col-span-1 sm:col-span-2 cursor-not-allowed">
                    選擇取件人員
                  </button>
                );
              }
            })()}
          </div>
          <div className="text-main-gray text-base sm:text-xl font-semibold mt-8 sm:mt-10">
            護照/台胞證是否辦理完成？
          </div>
          {(() => {
            if (data.is_picked_up === true && data.is_completed === false) {
              return (
                <button
                  className="bg-[#EE1D52] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 hover:bg-[#FFC5C5] hover:text-[#EE1D52]"
                  onClick={handleNotifyCompletion}
                  disabled={isNotifying}
                >
                  {isNotifying ? "處理中..." : "回件"}
                </button>
              );
            } else if (
              data.is_picked_up === true &&
              data.is_completed === true
            ) {
              return (
                <button className="bg-[#71AC4D] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 cursor-default flex justify-center items-center">
                  <img alt="check svg" src={check} className="w-6 h-6 mr-2" />
                  已辦理完成
                </button>
              );
            } else if (
              data.is_picked_up === false &&
              data.is_completed === false
            ) {
              return (
                <button className="bg-[#DEDEDE] text-white text-base sm:text-xl font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-4 cursor-not-allowed">
                  回件
                </button>
              );
            }
          })()}
          <div className="w-full xl:w-[1200px] text-base sm:text-xl bg-white grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-4 py-6 px-4 rounded-lg shadow-md mt-8 sm:mt-10 mb-24 items-center">
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">送件區域</p>
              <p className="text-main-gray mt-3">
                {data.delivery_area || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">送件地址</p>
              <p className="text-main-gray mt-3">
                {data.delivery_address || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">送件日期</p>
              <p className="text-main-gray mt-3">
                {data.delivery_date || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3">
              <p className="font-semibold text-main-gray">送件時段</p>
              <p className="text-main-gray mt-3">
                {data.delivery_time || "未選擇"}
              </p>
            </div>
            <div className="bg-[#F7F7F9] rounded-xl flex flex-col p-3 col-span-1 sm:col-span-2">
              <p className="font-semibold text-main-gray">送件人員</p>
              <p className="text-main-gray mt-3">
                {data.delivery_person || "未選擇"}
              </p>
            </div>
            {data.application_status === "READY_FOR_DELIVERY" &&
            data.delivery_area !== "" &&
            data.delivery_address !== null &&
            data.delivery_date !== null &&
            data.delivery_time !== null ? (
              <Link
                to={`/choosedelivery/${application_id}`}
                className="flex justify-center items-center col-span-1 sm:col-span-2 mt-1 sm:mt-10"
              >
                <button className="bg-btn01 text-white font-semibold w-full sm:w-[460px] h-[50px] rounded-full hover:bg-[#66C0DD] active:bg-[#2F99BA]">
                  選擇送件人員
                </button>
              </Link>
            ) : (
              <button className="bg-[#DEDEDE] text-white font-semibold w-full sm:w-[460px] h-[50px] rounded-full mt-10 mx-auto col-span-1 sm:col-span-2 cursor-not-allowed">
                選擇送件人員
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationDetailPage;
