import React, { useState,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route,useLocation} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./page/Home";
import SingUp from "./page/Singup";
import FindPassword from "./page/Findpassword";
import Custom from "./page/Custom";
import Progress from "./page/Progress";
import ProgressState from "./page/progress/ProgressState";
// import FailedPay from "./page/progress/FailedPay";
// import ProgressChosePay from "./page/progress/ProgressChosePay";
// import ProgressContinue from "./page/progress/ProgressContinue";
// import ProgressReport from "./page/progress/ProgressReport";
// import ProgressPayDone from "./page/progress/ProgressPayDone";
import NavBar from "./components/Navbar";
import DataManagementPage from "./backend/pages/DataManagementPage";
import ApplicationDetailPage from "./backend/pages/ApplicationDetailPage";
import ChoosePickupPage from "./backend/pages/ChoosePickupPage";
import ChooseDeliveryPage from "./backend/pages/ChooseDeliveryPage";
import AutoLogoutWrapper from "./backend/component/AutoLogoutWrapper";

const queryClient = new QueryClient();

function ModalHandler({ setIsModalOpen }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/custom") {
      // setIsModalOpen(true); 
    }
  }, [location.pathname, setIsModalOpen]);

  return null;
}

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false); // 默認設置為 true，模態框預設打開
  // const [applicationStatus, setApplicationStatus] = useState(""); // 管理 applicationStatus 狀態
  // const [isOfflinePayment, setIsOfflinePayment] = useState(true); // 管理 isOfflinePayment 狀態

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear(); 
    setIsModalOpen(true); 
  };

  // const handleSetStatus = (status, isOffline) => {
  //   setApplicationStatus(status);
  // };

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AutoLogoutWrapper>
          <ModalHandler setIsModalOpen={setIsModalOpen} />
          <Routes>
            <Route path="/" element={<Home setIsModalOpen={setIsModalOpen}/>} />
            <Route path="/singup" element={<SingUp />} />
            <Route path="/findpassword" element={<FindPassword />} />
            <Route
              path="/custom"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <Custom isModalOpen={isModalOpen} closeModal={closeModal} />
                </>
              }
            />
            <Route path="/datamanagement" element={<DataManagementPage />} />
            <Route path="/applicationdetail/:application_id" element={<ApplicationDetailPage />} />
            <Route path="/choosepickup/:application_id" element={<ChoosePickupPage />} />
            <Route path="/choosedelivery/:application_id" element={<ChooseDeliveryPage />} />
            <Route
              path="/progress/"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <Progress isModalOpen={isModalOpen} closeModal={closeModal} />
                </>
              }
            />
            <Route
              path="/progress/status"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <ProgressState
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    // applicationStatus={applicationStatus}
                  />
                </>
              }
            />
            {/* <Route
              path="/progress/status/failedpay"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <FailedPay
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    applicationStatus={applicationStatus}
                    isOfflinePayment={isOfflinePayment}
                  />
                </>
              }
            />
            <Route
              path="/progress/status/choose"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <ProgressChosePay 
                  setApplicationStatus={handleSetStatus}
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  />
                </>
              }
            />
            <Route
              path="/progress/status/continue"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <ProgressContinue 
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                  />
                </>
              }
            />
            <Route
              path="/progress/status/report"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <ProgressReport 
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                  />
                </>
              }
            />
            <Route
              path="/progress/status/done"
              element={
                <>
                  <NavBar onOpenModal={openModal} onLogout={handleLogout} />
                  <ProgressPayDone 
                      isModalOpen={isModalOpen}
                      closeModal={closeModal}
                  />
                </>
              }
            /> */}
          </Routes>
        </AutoLogoutWrapper>
      </Router>
  </QueryClientProvider>
  );
}

export default App;
