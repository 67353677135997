import React, { useRef, useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import DatePicker from "react-datepicker";
import Hint from "../Hint";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import upload from "../../image/upload.svg";
import first from "../../image/first.svg";
import second from "../../image/second.svg";
import date from "../../image/datepicker.svg";
import close from "../../image/cross.svg";
import closes from "../../backend/images/cross.svg"
import idcard from "../../image/idcard.svg";
import Loading from "../Loading";

function CompatriotQuestion( { setForms, formIndex,passportIdNumber }) {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [formDate, setFormDate] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [hintMessage, setHintMessage] = useState("");
  const [hintTitle, setHintTitle] = useState("");
  const [delivery_option, setDeliveryOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null); // 管理選中的文件

  

  // 新增狀態來存儲表單資料
  const [applicantName, setApplicantName] = useState("");
  const [applicantPhone, setApplicantPhone] = useState("");
  const [selectNormal, setSelectNormal] = useState(null);
  const [selectFirst, setSelectFirst] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [birthDateInput, setBirthDateInput] = useState(""); // 管理用戶選擇的日期
  const [placeholderDate, setPlaceholderDate] = useState(""); // 管理初始的placeholder
  const [age, setAge] = useState("");
  const [checkedStates, setCheckedStates] = useState({
    need_two_inch_photo: false,             // 2 吋照片
    need_ID: false,                         // 身分證正反面影本
    need_passport: false,                   // 護照正本
    need_household_registry: false,         // 戶口名簿影本
    need_legal_representative: false,       // 法定代理人身分證影本
    need_resident_certificate: false,       // 台灣地區居留證
    need_police_report: false,              // 報案證明
    need_old_compatriot: false,             // 台胞證舊證
    need_other_document: false,             // 其他證件
});

useEffect(() => {
  setForms((prevForms) =>
    prevForms.map((form, index) => {
      if (index === formIndex) {
        const formattedBirthDate = birthDate ? birthDate.toISOString().split('T')[0] : ""; // 將日期轉換為 YYYY-MM-DD 格式
        return { ...form, applicantName, applicantPhone, birthDate: formattedBirthDate, birthDateInput, idNumber, files, age, formDate, checkedStates, delivery_option };
      }
      return form;
    })
  );
}, [applicantName, applicantPhone, birthDate, birthDateInput, idNumber, files, age, formDate, checkedStates, delivery_option, setForms, formIndex]);

  

  

useEffect(() => {
  const today = new Date();
  
  // 保持填表日期為 YYYY-MM-DD 格式
  const formattedDate = today.toISOString().slice(0, 10);
  setFormDate(formattedDate);
  
  // 將出生日期的 placeholder 設置為民國年格式 YYY-MM-DD
  const minguoYear = today.getFullYear() - 1911;
  const formattedMinguoDate = `${minguoYear}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  setPlaceholderDate(formattedMinguoDate);
}, []);

  const handleClick = () => {
    if (idNumber.trim() === "") {
      setHintTitle("請先輸入身分證 !!");
      setHintMessage("");
      setShowHint(true);
    } else {
      setShowHint(false);
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFormats = ["image/jpeg", "image/png", "image/heif", "image/heic", "image/gif"];
      if (!validFormats.includes(file.type)) {
        setHintTitle("檔案格式錯誤");
        setHintMessage("請確認上傳的檔案是否為以下格式 :  .jpeg、.png、.HEIF、.HEIC、.gif");
        setShowHint(true);
        return;
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      let timeoutId = setTimeout(() => {
        setIsLoading(true);
      }, 500); // 設置0.5秒的延遲

      try {
        const compressedFile = await imageCompression(file, options);
        console.log("壓縮後的文件:", compressedFile);

        const fileNumber = files.length + 1;
        const newFileName = `${idNumber}_C_${fileNumber}.${compressedFile.type.split('/')[1]}`;

        console.log("選擇的檔案:", compressedFile);
        console.log("自訂檔案名稱:", newFileName);

        setFiles((prevFiles) => [
          ...prevFiles,
          {
             name: compressedFile.name,
              size: compressedFile.size,
               customName: newFileName,
               compressedFile
              },
        ]);
        clearTimeout(timeoutId);
      } catch (error) {
        console.error("壓縮圖片時出錯:", error);
        clearTimeout(timeoutId);
      }finally{
        setIsLoading(false)
      }
    }
  };

  const handleDateClick = () => {
    setIsDatePickerOpen((prevState) => !prevState);
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return "";
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateSelect = (date) => {
    setBirthDate(date);  // 保留原始日期
    setIsDatePickerOpen(false);
    const formattedMinguoDate = formatToMinguoYear(date);  // 顯示的民國年格式
    setBirthDateInput(formattedMinguoDate);
    setAge(calculateAge(date));
    setIsDatePickerOpen(false);  
};



const handleInputChange = (event) => {
  const inputDate = event.target.value;
  setBirthDateInput(inputDate);
  if (/^\d{3}-\d{2}-\d{2}$/.test(inputDate)) { // 確保格式為YYY-MM-DD
      const [year, month, day] = inputDate.split("-");
      const gregorianYear = parseInt(year, 10) + 1911;
      const formattedDate = new Date(`${gregorianYear}-${month}-${day}`);
      if (!isNaN(formattedDate)) {
          setBirthDate(formattedDate); // 保留原始日期
          setAge(calculateAge(formattedDate));
      } else {
          setAge("");
      }
  } else {
      setAge("");
  }
};

const handleCheckboxChange = (key) => (event) => {
  const isChecked = event.target.checked;
  const checkedCount = Object.values(checkedStates).filter((checked) => checked).length;

  // 如果目前勾選的 checkbox 只有一個且要取消勾選，則直接返回
  if (checkedCount === 1 && !isChecked) return;

  // 更新 checkedStates 狀態
  setCheckedStates((prevState) => ({
    ...prevState,
    [key]: isChecked,
  }));
};


  const formatToMinguoYear = (date) => {
    if (!date) return "";
    const year = (date.getFullYear() - 1911).toString().padStart(3, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const normalOptions = [
    { label: "一般件", value: "REGULAR" },
    { label: "速件", value: "EXPRESS" },
  ];

  const passportOptions = [
    { label: "首次申請台胞證", value: "FIRST_TIME" },
    { label: "台胞證照換發", value: "RENEWAL" },
    { label: "台胞證遺失/污損補發", value: "REPLACEMENT" },
  ];

const handleNormalClick = (value) => {
  setSelectNormal(value);
  setDeliveryOption(value);
};


const handleFirstClick = (value) => {
  setSelectFirst(value);
  setForms((prevForms) =>
    prevForms.map((form, index) =>
      index === formIndex
        ? { ...form, selectFirst: value } 
        : form
    )
  );
};



useEffect(() => {
  const newCheckedStates = {
    need_two_inch_photo: false,            // 2 吋照片
    need_ID: false,                        // 身分證正反面影本
    need_passport: false,                  // 護照正本
    need_old_compatriot: false,            // 台胞證舊證
    need_household_registry: false,        // 戶口名簿影本
    need_legal_representative: false,      // 法定代理人身分證影本
    need_resident_certificate: false,      // 台灣地區居留證
    need_police_report: false,             // 報案證明
    need_other_document: false,            // 其他證件
  };

  if (age >= 16) {
    newCheckedStates.need_ID = true;
    newCheckedStates.need_two_inch_photo = true;
    newCheckedStates.need_passport = true;
    if (selectFirst === 'RENEWAL') {
      newCheckedStates.need_old_compatriot = true; 
    } else if (selectFirst === 'REPLACEMENT') {
      newCheckedStates.need_police_report = true; 
    }
  }

  if (age < 16) {
    newCheckedStates.need_ID = true;
    newCheckedStates.need_two_inch_photo = true;
    newCheckedStates.need_passport = true;
    newCheckedStates.need_household_registry = true;
    newCheckedStates.need_legal_representative = true
    if (selectFirst === 'RENEWAL') {
      newCheckedStates.need_old_compatriot = true; 
    } else if (selectFirst === 'REPLACEMENT') {
      newCheckedStates.need_police_report = true; 
    }
  }
  

  // 如果年齡為 0 歲（需要法定代理人和居留證）
  if (age === 0) {
    newCheckedStates.need_legal_representative = true;
  }

  // 確保根據年齡和選項的邏輯，清除不必要的文件
  if (age === null || age === undefined || age === "") {
    newCheckedStates.need_household_registry = false;
    newCheckedStates.need_legal_representative = false;
  }

    // 根據 passportIdNumber 和 idNumber 的匹配情況設置 need_passport
    if (idNumber === passportIdNumber) {
      newCheckedStates.need_passport = false;
    }

  setCheckedStates(newCheckedStates);
}, [age, selectFirst, idNumber, passportIdNumber]);




  return (
    <div className="bg-white  px-4 sm:px-6 md:px-4 py-4 sm:py-6 grid max-w-[954px] sm:w-full gap-[24px] shadow-md rounded-xl mb-[40px]">
      <div className="grid gap-y-[24px]">
        <div className="flex items-center">
          <img src={first} alt="" />
          <span className="ml-[12px] text-base sm:text-[20px] md:text-[24px] font-bold">旅客背景資料</span>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[32px] gap-y-[24px] w-full">
          {/* 姓名 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">旅客姓名</span>
            <input
              type="text"
              placeholder="輸入旅客姓名"
              className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px]"
              value={applicantName}
              onChange={(e) => setApplicantName(e.target.value)}
            />
          </div>
          {/* 日期 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">填表日期</span>
            <input
              type="text"
              value={formDate}
              disabled
              className="border rounded-md bg-[#F7F7F9] p-[14px] h-[53px]"
            />
          </div>
          {/* 身分證 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">身分證字號</span>
            <input
              type="text"
              placeholder="輸入身分證字號"
              onChange={(e) => setIdNumber(e.target.value)}
              className="border rounded-md Input bg-[#F7F7F9] p-[14px] h-[53px]"
            />
          </div>
          {/* 出生日期 */}
          <div className="grid gap-y-[16px]">
            <div>
              <span className="font-bold text-base sm:text-[18px] mr-2">出生日期</span>
              <span className="text-[#EE1D52] text-base sm:text-[18px] hidden md:inline">
                民國出生日期(YYY-MM-DD)
              </span>
              <span className="text-[#EE1D52] text-[14px] sm:text-base md:hidden">
                民國出生日期(YYY-MM-DD)
              </span>
            </div>
            <div className="relative">
              <input
                type="text"
                value={birthDateInput}  // 顯示用戶選擇的日期
                placeholder={placeholderDate}  // 顯示初始的民國年日期
                onChange={handleInputChange}
                className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px] w-full pr-10"
                onClick={handleDateClick}
                readOnly
              />
              <img
                src={date}
                alt="date"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={handleDateClick}
              />
              {isDatePickerOpen && (
                <div className="absolute w-full flex justify-end z-10 right-0 mt-2">
                  <DatePicker
                    selected={birthDate}
                    onChange={handleDateSelect}
                    inline
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    maxDate={new Date()}
                    locale={zhTW}
                    className="react-datepicker"
                  />
                </div>
              )}
            </div>
          </div>
          {/* 連絡電話 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">連絡電話</span>
            <input
              type="text"
              placeholder="請輸入電話號碼"
              className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px]"
              value={applicantPhone}
              onChange={(e) => setApplicantPhone(e.target.value)}
            />
          </div>
          {/* 年齡 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">年齡</span>
            <input
              type="text"
              value={age}
              className="border rounded-md bg-[#F7F7F9] p-[14px] h-[53px]"
              readOnly
              disabled
            />
          </div>
        </div>
        {/* 送件類別 */}
        <div className="grid gap-y-[16px]">
          <span className="font-bold text-base sm:text-[18px]">送件類別</span>
          <div className=" grid grid-cols-2 sm:grid-cols-3  gap-x-[10px]">
            {normalOptions.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px] rounded-lg  text-center ${
                  selectNormal === option.value ? "bg-orange text-white" : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleNormalClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        {/* 申請護照類別 */}
        <div className="grid gap-y-[16px]">
          <span className="font-bold text-base sm:text-[18px]">申請台胞證類別</span>
          <div className="flex flex-col sm:flex-row gap-y-[10px] gap-x-[10px]">
            {passportOptions.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px] rounded-lg w-full text-center ${
                  selectFirst === option.value ? "bg-orange text-white" : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleFirstClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          {selectFirst === 'REPLACEMENT' &&(
          <ul className="list-disc pl-8 text-[#EE1D52] text-base sm:text-[18px]">
            <li>如需補發者，請自行勾選下方的台胞證舊證選項，並上傳台胞證舊證照片</li>
          </ul>
          ) }
        </div>

        {/* 附件資料和上傳文件部分只在選擇護照類別後顯示 */}
        {selectFirst && (
          <>
            {/* 附件資料 */}
            <div className="grid gap-y-[16px]">
              <span className="font-bold text-base sm:text-[18px]">請備齊勾選的應繳附件資料</span>
              <div className="grid gap-y-2">
                <div className="m-y-[16px] text-[14px] sm:text-base sm:text-[18px]">
                  應備文件
                  <ul className="list-disc pl-8 text-base sm:text-[18px]">
                    <li>改名或有雙胞胎者須附6個月內戶籍謄本</li>
                    <li>未滿16歲者須附戶籍謄本（戶口名簿）及監護人其中一人的身分證影本</li>
                    <li>曾申辦過台胞證者須附上舊台胞證正反面影本（有效或過期都需要）</li>
                  </ul>
                </div>
                <div className="grid gap-y-2 text-base sm:text-[18px]">
                  {/* 照片 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox1"
                      checked={checkedStates.need_two_inch_photo}
                      onChange={handleCheckboxChange("need_two_inch_photo")}
                    />
                    <label htmlFor="checkbox1">2吋照片</label>
                  </div>
                  {/* 身分證 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox2"
                      checked={checkedStates.need_ID}
                      onChange={handleCheckboxChange("need_ID")}
                    />
                    <label htmlFor="checkbox2">身分證正反面影本</label>
                  </div>
                  {/* 護照正本 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox3"
                      checked={checkedStates.need_passport}
                      onChange={handleCheckboxChange("need_passport")}
                    />
                    <label htmlFor="checkbox3">護照正本(有效期7個月以上)</label>
                  </div>
                  {/* 戶口名簿 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox5"
                      checked={checkedStates.need_household_registry}
                      onChange={handleCheckboxChange("need_household_registry")}
                    />
                    <label htmlFor="checkbox5">戶口名簿／6個月內戶籍謄本</label>
                  </div>
                  {/* 法定代理人 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox6"
                      checked={checkedStates.need_legal_representative}
                      onChange={handleCheckboxChange("need_legal_representative")}
                    />
                    <label htmlFor="checkbox6">法定代理人身分證</label>
                  </div>
                  {/* 報案證明 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox8"
                      checked={checkedStates.need_police_report}
                      onChange={handleCheckboxChange("need_police_report")}
                    />
                    <label htmlFor="checkbox8">報案證明正本（有效舊台胞證遺失者）</label>
                  </div>
                  {/* 舊證 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox4"
                      checked={checkedStates.need_old_compatriot}
                      onChange={handleCheckboxChange("need_old_compatriot")}
                    />
                    <label htmlFor="checkbox4">台胞證舊證</label>
                  </div>
                  {/* 非台灣出生者證明 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox7"
                      checked={checkedStates.need_resident_certificate}
                      onChange={handleCheckboxChange("need_resident_certificate")}
                    />
                    <label htmlFor="checkbox7">非台灣出身者需附的文件證明</label>
                  </div>
                  {/* 其他 */}
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                      id="checkbox9"
                      checked={checkedStates.need_other_document}
                      onChange={handleCheckboxChange("need_other_document")}
                    />
                    <label htmlFor="checkbox9">其他證件</label>
                  </div>
                </div>
              </div>
            </div>
            {/* 上傳文件 */}
            <div>
              <div className="flex items-center mb-2">
                <img src={second} alt="" />
                <span className="ml-[12px] text-base sm:text-[20px] sm:text-[24px] font-bold">上傳文件</span>
              </div>
              <div className="h-[75px]">
                <span className="text-base sm:text-[18px]">
                  請上傳<span className="font-bold">旅客的護照、身分證、相片、戶籍謄本、戶口名簿、舊證、監護人身分證、其他證件</span>
                  <br />
                  請確認上傳的檔案是否為以下格式：.jpeg、.png、.HEIF、.HEIC、.gif
                </span>
              </div>
              <div className="flex justify-center mt-[24px]">
                <div
                  className={`h-[306px] w-full bg-[#F7F7F9] border-2 border-[#dedede] rounded-lg border-dotted flex flex-col justify-center items-center cursor-pointer`}
                  onClick={handleClick}
                >
                  <img alt="upload" src={upload} className="h-8 sm:h-12 w-12 mb-2" />
                  <div className="flex pb-2">
                    <p className=" text-2xl hidden sm:flex">檔案上傳</p>
                  </div>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {showHint && <Hint title={hintTitle} message={hintMessage} onClose={() => setShowHint(false)} />}
            <div className="grid sm:grid-cols-3 gap-x-4 gap-y-6">
            {files.map((file, index) => (
              <div
                key={index}
                className="relative bg-[#F7F7F9] rounded-xl p-4 flex items-center"
              >
                <div className="mr-4">
                  {file.compressedFile && file.compressedFile.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file.compressedFile)} // 顯示上傳圖片的縮圖
                      alt={file.customName}
                      className="w-[40px] h-[27px] object-cover cursor-pointer"
                      onClick={() => setSelectedFile(file)} // 點擊縮圖後設置選中的文件
                    />
                  ) : (
                    <img src={idcard} alt="" className="w-[40px] h-[27px]" />
                  )}
                </div>
                <div className="max-w-[65%] break-words">
                  <p className="text-[14px] sm:text-base font-bold">
                    {file.name.length > 10
                      ? `${file.name.slice(0, 10)}...${file.name.slice(file.name.lastIndexOf("."))}`
                      : file.name}
                  </p>
                  <p className="text-[14px] sm:text-base text-[#A3A3A3]">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
                <img
                  src={close}
                  alt="刪除圖標"
                  className="absolute top-4 right-4 cursor-pointer w-[18px] h-[18px]"
                  onClick={() => {
                    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                  }}
                />
              </div>
            ))}
            {selectedFile && (
              <div
                className="fixed inset-0 bg-black/60 z-30 flex justify-center items-center"
                onClick={() => setSelectedFile(null)} // 點擊模態框外部關閉
              >
                <div
                  className="w-full sm:w-auto h-auto p-14 my-6 mx-4 flex items-center justify-center relative"
                  onClick={(e) => e.stopPropagation()} // 阻止點擊事件冒泡
                >
                  <img
                    alt={selectedFile.name}
                    src={URL.createObjectURL(selectedFile.compressedFile)} // 使用本地圖片URL
                    className="max-w-full max-h-[85vh] object-contain"
                  />
                  <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    onClick={() => setSelectedFile(null)} // 點擊按鈕關閉模態框
                  >
                    <img alt="cross" src={closes} className="w-8 h-8" />
                  </button>
                </div>
              </div>
            )}
            </div>
          </>
        )}
      </div>
      {isLoading && <Loading/>}
    </div>
  );
}

export default CompatriotQuestion;
