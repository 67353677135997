import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import apiBaseURL from "../../config"; // 引入 apiBaseURL
import WaitingPickup from "../../components/progress/WaitingPickup";
import Processing from "../../components/progress/Processing";
import Paid from "../../components/progress/Paid";
import WaitingDelivery from "../../components/progress/WaitingDelivery";
import Completed from "../../components/progress/Completed";
import one from "../../image/one.svg";
import two from "../../image/two.svg";
import three from "../../image/three.svg";
import four from "../../image/four.svg";
import check from "../../image/check.svg";
// import Approved from "../../components/progress/Approved";
// import ProgressPayDone from "./ProgressPayDone";

function ProgressState({isModalOpen, closeModal, applicationStatus: initialStatus, isOfflinePayment: initialOfflinePayment}) {
  const location = useLocation();
  const application_id =localStorage.getItem("application_id");
  const [applicationStatus, setApplicationStatus] = useState(initialStatus); 
  const [isOfflinePayment, setIsOfflinePayment] = useState(initialOfflinePayment);
  const [paymentMethod, setPaymentMethod] = useState(null); 
  const [IsApproved, setIsApproved] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    setIsLoading(true);
    if (location.state?.resetOfflinePayment) {
      setIsOfflinePayment(false);
  }

    if (!isOfflinePayment) {
      // 手動設置狀態，用於測試
      // setApplicationStatus("READY_FOR_DELIVERY");

      //從後端獲取狀態
      const fetchStatus = async () => {
        try {
          const token = localStorage.getItem("access_token"); 
          const response = await axios.get(
            `${apiBaseURL}/v1/traveler/applications/${application_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            }
          );
          const { application_status, payment_method,is_approved } = response.data.data;
          setApplicationStatus(application_status); 
          setPaymentMethod(payment_method); 
          setIsApproved(is_approved);

          if (application_status === "PROCESSING") {
            navigate("/progress");
          }

        } catch (error) {
          console.error("Error fetching application status:", error);
        } finally {
          setIsLoading(false); 
        }
      };
      fetchStatus();
    }
  }, [application_id, location.pathname, isOfflinePayment, location.state?.resetOfflinePayment,navigate]); 
  
  const renderSteps = (steps) => {
    const statusToStepMap = {
      // WAITING_FOR_ADDITIONAL_DOCUMENT: 1, //完成匯款
      WAITING_FOR_PICKUP_INFO: 0,
      READY_FOR_PICKUP: IsApproved ? 2 : 1, 
      APPROVED: 2,//已批准
      PAID: 3, 
      WAITING_FOR_DELIVERY_INFO: 3,
      READY_FOR_DELIVERY: 4,
      COMPLETED: 5,
    };
  
    const currentStep = statusToStepMap[applicationStatus];
  
    return steps.map((step, index) => {
      const isCompleted = index < currentStep;
      const isCurrent = index === currentStep;
      const shouldHighlightLine = index < currentStep;
  
      return (
        <div className="flex items-center text-[14px] sm:text-[12px]" key={step}>
          <div
            className={`w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-full border-2 ${
              isCompleted
                ? "bg-orange border-orange"
                : isCurrent
                ? "border-orange text-orange"
                : "border-[#A3A3A3] text-[#A3A3A3]"
            }`}
          >
            {isCompleted ? (
              <img src={check} alt="check" />
            ) : (
              step
            )}
          </div>
          {index < steps.length - 1 && (
            <div
              className={`w-[29px] sm:w-20 h-[2px] ${
                shouldHighlightLine ? "bg-orange" : "bg-[#A3A3A3]"
              }`}
            ></div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="flex justify-center items-center flex-col sm:px-8 px-4 mt-[88px]">
      {applicationStatus !== "WAITING_FOR_REMITTANCE_CONFIRMATION" && (
        <div className="mt-[50px] gap-y-[50px] flex flex-col items-center">
          <div className="flex items-center justify-between mb-[50px]">
            {renderSteps(["01", "02", "03", "04", "05"])}
          </div>
        </div>
      )}
        <div className=" min-w-[288px] max-w-[954px] w-full mb-[100px] ">
          {
            isLoading ? (
              <div></div>
            ) : (
              <>
                {["READY_FOR_PICKUP", "WAITING_FOR_DELIVERY_INFO", "READY_FOR_DELIVERY", "COMPLETED","APPROVED","PAID"].includes(applicationStatus) ? (
                  <>
                    {(applicationStatus === "READY_FOR_PICKUP" ||applicationStatus === "APPROVED" ||applicationStatus === "PAID") && <WaitingPickup isOfflinePayment={isOfflinePayment} application_id={application_id} payment_method={paymentMethod}/>}
                    {(applicationStatus === "WAITING_FOR_DELIVERY_INFO") && <Processing setApplicationStatus={setApplicationStatus} application_id={application_id}/>}
                    {applicationStatus === "READY_FOR_DELIVERY" && <WaitingDelivery application_id={application_id} />}
                    {applicationStatus === "COMPLETED" && <Completed application_id={application_id} />}
                  </>
                ) : (
                  <Paid status={applicationStatus} isOfflinePayment={isOfflinePayment} setApplicationStatus={setApplicationStatus} setIsOfflinePayment={setIsOfflinePayment} application_id={application_id}/>
                )}
              </>
            )
          }
        </div>
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫回件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
}

export default ProgressState;
