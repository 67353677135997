import React, { useState,useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import eye from "../image/eye.svg";
import eyeclose from "../image/closeeye.svg";
import success from "../image/success.svg";
import axios from "axios";
import apiBaseURL from "../config";

function Signup() {
  const [setPassword, setSetPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPasswordValue] = useState("");
  const [passwordError, setPasswordError] = useState(""); // 狀態用於儲存密碼錯誤訊息
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(""); // 狀態用於儲存電子郵件錯誤訊息
  const [isRegistered, setIsRegistered] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(""); // 新增狀態儲存驗證碼錯誤訊息
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();

  const toggleSetPasswordVisibility = () => {
    setSetPassword(!setPassword);
  };

  const toggleCheckPasswordVisibility = () => {
    setCheckPassword(!checkPassword);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
  
    // 驗證密碼格式和確認密碼
    const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
    if (!passwordFormat.test(password)) {
      setPasswordError("密碼必須包含英文字母和數字");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("密碼不相符");
      return;
    }
  
    // 直接打註冊 API，傳送 account 和 password
    try {
      await axios.post(`${apiBaseURL}/v1/user/register`, {
        account: email,
        password: password,
      });
      console.log("註冊成功，驗證碼已發送");
  
      // 顯示驗證碼輸入框
      setIsVerificationSent(true);
    } catch (error) {
      console.error("註冊失敗：", error.response);
      alert("註冊失敗，請檢查您的網絡連接！");
    }
  };
  

  const handleCodeSubmit = async () => {
    try {
      setCodeError("");
      // 驗證驗證碼，並傳送 account、code 和 password
      const verifyResponse = await axios.post(`${apiBaseURL}/v1/user/verify_register_verification_code`, {
        account: email,
        code: code,
        password: password,
      });
  
      if (verifyResponse.status === 200) {
        console.log("驗證成功並註冊完成");
        setIsRegistered(true);  // 顯示註冊成功訊息
      }
    } catch (error) {
      console.error("驗證碼驗證失敗或註冊失敗：", error.response);
      setCodeError("驗證碼輸入錯誤");
    }
  };
  
  

  const handleBackToLogin = () => {
    navigate("/"); 
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPasswordValue(newPassword);

    const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;

    if (!passwordFormat.test(newPassword)) {
      setPasswordError("密碼必須為8至16個英數字混合");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    // 每次確認密碼更改時驗證兩次密碼是否相同
    if (e.target.value !== password) {
      setPasswordError("密碼不相符");
    } else {
      setPasswordError(""); 
    }
  };

  const handleResendCode = async () => {
    setTimer(60);
    setCodeError("");
    // 呼叫發送驗證碼的 API，只傳送 email
    try {
      await axios.post(`${apiBaseURL}/v1/user/send_register_verification_code`, {
        email: email,
      });
      console.log("重新發送驗證碼");
    } catch (error) {
      console.error("重新發送驗證碼失敗：", error.response);
      alert("重新發送驗證碼失敗，請檢查您的網絡連接！");
    }
  };
  

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  return (
    <div className="flex justify-center items-center w-screen h-screen plane bg-center bg-no-repeat bg-cover p-4">
      {!isRegistered ? (
        <div className="w-full relative sm:w-[525px] sm:h-[664px] min-h-[480px] py-4 px-4 sm:px-8 sm:pl-[35px] sm:pr-[31px] bg-white rounded-[20px]">
          <div className="w-full h-[59px] flex justify-center">
            <div>
              <p className={`text-lg xsm:text-xl pb-2 gradient-underline`}>
                旅客
              </p>
            </div>
          </div>
          {!isVerificationSent?(
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <div className="grid gap-y-[12px] xsm:gap-y-[24px]">
                <div className="mb-2 text-btn01 text-xl xsm:text-[28px] font-semibold">
                  註冊
                </div>
                <div className="xsm:h-[111px] mb-4 xsm:mb-0">
                  <label
                    className="block text-gray-700 text-base mb-2"
                    htmlFor="email"
                  >
                    帳號
                  </label>
                  <input
                    className={`border rounded-xl h-[52px] w-full py-2 px-3 Input
                    ${emailError ? "border-[#EE1D52]" : ""}`}
                    id="email"
                    type="email"
                    placeholder="請輸入Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <p className="text-[#EE1D52] text-sm">{emailError}</p>
                  )}
                </div>
                <div className="relative xsm:h-[107px] xsm:mb-0">
                  <label
                    className="block text-gray-700 text-base mb-2"
                    htmlFor="setPassword"
                  >
                    設定密碼
                  </label>
                  <input
                    className={`rounded-xl border h-[52px] w-full py-2 px-3 pr-10 Input 
                    ${passwordError ? "border-[#EE1D52]" : ""}`}
                    id="setPassword"
                    type={setPassword ? "text" : "password"}
                    placeholder="輸入密碼"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <span className="h-[20px] text-xs sm:text-sm text-[#545454]">
                    請設定英文字母與數字組合8-16字元的密碼
                  </span>
                  <img
                    src={setPassword ? eye : eyeclose}
                    alt={setPassword ? "隱藏密碼" : "顯示密碼"}
                    className="absolute right-3 top-[54%] xsm:top-[56%] transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleSetPasswordVisibility}
                  />
                </div>
                <div className="relative h-[107px]">
                  <label
                    className="block text-gray-700 text-base mb-2"
                    htmlFor="checkPassword"
                  >
                    確認密碼
                  </label>
                  <input
                    className={`rounded-xl border h-[52px] w-full py-2 px-3 pr-10 Input 
                    ${passwordError ? "border-[#EE1D52]" : ""}`}
                    id="checkPassword"
                    type={checkPassword ? "text" : "password"}
                    placeholder="再次輸入密碼"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {passwordError && (
                    <p className="text-[#EE1D52] text-sm">{passwordError}</p>
                  )}
                  <img
                    src={checkPassword ? eye : eyeclose}
                    alt={checkPassword ? "隱藏密碼" : "顯示密碼"}
                    className="absolute right-3 top-[54%] xsm:top-[56%] transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleCheckPasswordVisibility}
                  />
                </div>
                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-lg rounded-[30px] h-[57px] text-white font-bold py-2 px-4 "
                  >
                    下一步
                  </button>
                  <div className="flex justify-center mt-2 items-center mb-3">
                    <p className="mr-1">已有帳號？</p>
                    <Link to="/">
                      <button className="text-[#4DA8C5] text-base font-bold underline underline-offset-2">
                        返回登入
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
          ):(
          <div>
            <div className="flex flex-col gap-y-8">
              <div>
                <p className="text-[19px] xsm:text-[28px] font-semibold text-[#4DA8C5]">
                  註冊
                </p>
              </div>
              <div className="h-[107px]">
                <p className="xsm:text-lg text-base mb-1 h-[27px]">驗證碼</p>
                <div className="grid grid-cols-2 sm:flex">
                  <input
                    className={`sm:w-[273px] mr-2 h-12 sm:h-[52px] pl-2 sm:pl-5 checkout-name rounded-xl border-[1px] text-base Input text-[#545454]`}
                    placeholder="請輸入驗證碼"
                    type="text"
                    value={code}
                    onChange={handleCodeChange}
                  />
                  <button
                    className={`sm:w-[180px] h-12 sm:h-[52px] sm:text-base font-semibold border-btn01 checkout-name rounded-xl border-[1px] ${timer > 0 ? "bg-[#DEDEDE] text-[#A3A3A3] border-none cursor-not-allowed" : "bg-white text-btn01"}  focus:border-[1px] focus:border-[#4DA8C5] focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-[#545454]`}
                    onClick={handleResendCode}
                    disabled={timer > 0}
                  >
                    重新取得驗證碼
                  </button>
                </div>
                {codeError && (
                  <div>
                    <span className="text-[14px] text-[#EE1D52]">{codeError}</span>
                  </div>
                )}
                {timer > 0 && (
                  <div className="flex justify-end">
                    <span className="font-[14px] text-[#A3A3A3]">等待 {timer} 秒</span>
                  </div>
                )}
              </div>
            </div>
            <div className="absolute bottom-0 w-full pr-8 sm:pr-[66px]">
              <button
                className={`rounded-full h-[56px] w-full mt-[80px] py-4 text-base xsm:text-lg font-medium ${
                  code
                    ? "bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white"
                    : "bg-[#DEDEDE] text-white cursor-not-allowed"
                }`}
                disabled={!code}
                onClick={handleCodeSubmit}
              >
                下一步
              </button>
              <div className="flex justify-center py-7">
                <p className="sm:text-base pt-0">已有帳號？</p>
                <Link to="/">
                  <button className="text-[#4DA8C5] sm:text-base font-bold underline underline-offset-2">
                    返回登入
                  </button>
                </Link>
              </div>
            </div>
          </div>
          )}
        </div>
      ) : (
        <div className="w-[525px] xsm:h-[664px] pl-[35px] pt-[21px] pr-[31px] bg-white rounded-[20px]">
          <div className="h-[570px] xsm:h-[612px] w-full flex flex-col justify-between">
            <div className="w-full h-[97px] flex justify-center">
              <div>
                <p className={`text-lg xsm:text-xl pb-2 gradient-underline`}>
                  旅客
                </p>
              </div>
            </div>
            <div className="w-full items-center flex flex-col">
              <img src={success} alt="" className="h-[50px] w-[50px]" />
              <span className="font-semibold text-base xsm:text-2xl mt-1 xsm:mt-3">
                註冊成功
              </span>
            </div>
            <button
              className="bg-btn01 rounded-[30px] h-[57px] text-white text-lg font-semibold py-2 px-4 mb-6 focus:outline-none focus:shadow-outline"
              onClick={handleBackToLogin}
            >
              請返回登入
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Signup;
