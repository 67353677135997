import React from "react";
// import loading from "../image/loading1.svg"

function Loading () {
  return(
    <div className='bg-black/70 fixed inset-0 flex justify-center  z-40'>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="circle"></div>
        <p className="text-white text-[24px]">Loading......</p>
      </div>
    </div>
  );
};

export default Loading