export const getApplicationTypeText = (type) => {
  switch (type) {
    case "PASSPORT":
      return "護照";
    case "COMPATRIOT":
      return "台胞證";
    case "PASSPORT_AND_COMPATRIOT":
      return "護照/台胞證";
    default:
      return "未知";
  }
};

export const getProcessText = (type) => {
  switch (type) {
    case "REGULAR":
      return "一般件";
    case "EXPRESS":
      return "急件";
    default:
      return "未知";
  }
};

export const getCategoryText = (type) => {
  switch (type) {
    case "FIRST_TIME":
      return "首次申請";
    case "RENEWAL":
      return "換發";
    case "REPLACEMENT":
      return "補發 (遺失補發)";
    default:
      return "無";
  }
};

export const getPaymentMethodText = (type) => {
  switch (type) {
    case "REMITTANCE":
      return "匯款";
    case "OFFLINE":
      return "線下支付";
    case "ONLINE":
      return "線上支付";
    default:
      return "未選擇";
  }
};

export const getPaymentStatusText = (type) => {
  switch (type) {
    case "PAID":
      return "已付款";
    case "UNPAID":
      return "未付款";
    case "PENDING":
      return "待確認";
    case "FAILED":
      return "失敗";
    default:
      return "未知";
  }
};

export const getPickupStatusText = (type) => {
  switch (type) {
    case "PICKED_UP":
      return "已取件";
    case "NOT_PICKED_UP":
      return "未取件";
    case "WAITING_FOR_ADDITIONAL_DOCUMENT":
      return "待補件";
    default:
      return "未知";
  }
};
