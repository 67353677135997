import React, { useState,useEffect } from "react";
import CompatriotQuestion from "./CompatriotQuestion";
import plus from "../../image/plus.svg";
import remove from "../../image/delete.svg";

function CompatriotFormData({onFormDataChange} ) {
  const [forms, setForms] = useState([
    {
      index: 0,
      type: "compatriot",
      files: [],
      formDate: "",
      idNumber: "",
      birthDate: null,
      birthDateInput: "",
      applicantName: "",
      applicantPhone: "",
      isDatePickerOpen: false,
      selectFirst: null,
    },
  ]);

  useEffect(() => {
    onFormDataChange(forms);
  }, [forms, onFormDataChange]);

  const handleAddCompatriotApplication = () => {
    if (forms.length >= 10) return;
    setForms((prevForms) => [
      ...prevForms,
      {
        index: prevForms.length,
        type: "compatriot",
        files: [],
        formDate: "",
        idNumber: "",
        birthDate: null,
        birthDateInput: "",
        applicantName: "",
        applicantPhone: "",
        isDatePickerOpen: false,
        selectFirst: null,
      },
    ]);
  };

  const handleRemoveForm = (index) => {
    setForms((prevForms) => prevForms.filter((form) => form.index !== index));
  };

  return (
    <div className=" min-w-[288px] max-w-[954px] w-full">
      {forms.map((form) => (
        <div key={form.index} className="relative">
          <CompatriotQuestion form={form} formIndex={form.index} setForms={setForms} />
          {forms.length > 1 && (
            <button
              className="absolute top-2 right-2"
              onClick={() => handleRemoveForm(form.index)}
            >
              <img src={remove} alt="移除表單" className="w-[24px] h-[24px]" />
            </button>
          )}
        </div>
      ))}
      <div className="flex flex-col items-center gap-y-[24px] mb-[40px]">
        <button
          className="border-2 border-black w-[256px] sm:w-[400px] h-[44px] rounded-lg flex items-center justify-center gap-x-2"
          onClick={handleAddCompatriotApplication}
        >
          <img src={plus} alt="" className="w-[24px] h-[24px]" />
          幫家人申請
        </button>
      </div>
    </div>
  );
}

export default CompatriotFormData;
