import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // 添加這行
import eye from "../image/eye.svg";
import eyeclose from "../image/closeeye.svg";
import apiBaseURL from "../config";

function CustomLogin({ setIsModalOpen }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const sendEmail = async () => {
    if (!email || !password) {
      setErrorMessage("請輸入帳號和密碼。");
      return;
    }

    // 數據構造
    const formData = new URLSearchParams();
    formData.append("username", email);
    formData.append("password", password);

    // API 請求
    try {
      const response = await axios.post(`${apiBaseURL}/login`, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.access_token) {
          localStorage.setItem("access_token", data.access_token);
          setIsModalOpen(true);
          navigate("/custom");
        } else {
          setErrorMessage("帳號或密碼錯誤，請輸入正確資料。");
        }
      } else {
        throw new Error("API 錯誤");
      }
    } catch (error) {
      console.error("登入失敗:", error);
      setErrorMessage("帳號或密碼錯誤，請輸入正確資料。");
    }
  };

  return (
    <div className="grid gap-y-[24px] px-4 xsm:px-[33px] pt-10 xsm:pt-[50px] xsm:h-[540px] relative">
      <p className="text-xl xsm:text-[28px] font-semibold xsm:h-[67px] text-[#4DA8C5]">
        登入
      </p>
      <div className="xsm:h-[107px]">
        <p className="text-base xsm:text-lg mb-1 xsm:h-[27px]">帳號</p>
        <input
          className={`w-full h-[52px] pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"} text-base focus:border-[1px] focus:border-[#4DA8C5] focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-[#545454]`}
          placeholder="mail@example.com"
          type="text"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div className="relative xsm:h-[107px] mt-6 xsm:mt-0">
        <p className="text-base xsm:text-lg mb-1 xsm:h-[27px]">密碼</p>
        <input
          className={`w-full h-[52px] pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"}  sm:text-base focus:border-[1px] focus:border-[#4DA8C5] focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-[#545454]`}
          placeholder="請輸入密碼"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <img
          src={showPassword ? eye : eyeclose}
          alt={showPassword ? "隱藏密碼" : "顯示密碼"}
          className="absolute right-[14px] top-[68%] xsm:top-[55%] w-[24px] h-[24px] transform -translate-y-1/2 cursor-pointer"
          onClick={togglePasswordVisibility}
        />
        <Link to="/findpassword">
          <span className="absolute right-0 bottom-[-24px] xsm:bottom-0 underline text-sm font-medium cursor-pointer">
            忘記密碼
          </span>
        </Link>
        {errorMessage && (
          <p className="text-red-500  text-[12px] xsm:text-sm mt-2 absolute ">
            {errorMessage}
          </p>
        )}
      </div>
      <button
        className={`rounded-full h-[56px] bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white w-full mt-6 text-lg font-bold`}
        onClick={sendEmail}
      >
        下一步
      </button>
      <div className="flex justify-center xsm:py-7 items-center mb-6">
        <p className="text-base mr-1">我還沒有帳號？</p>
        <Link to="/singup">
          <button className="text-[#4DA8C5] text-base font-bold underline underline-offset-2">
            註冊
          </button>
        </Link>
      </div>
    </div>
  );
}

export default CustomLogin;
