import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import eye from "../image/eye.svg";
import eyeclose from "../image/closeeye.svg";
import apiBaseURL from "../config";

function PasswordReset({ email, token, resetStatus }) {
  const [setPassword, setSetPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPasswordValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const toggleSetPasswordVisibility = () => {
    setSetPassword(!setPassword);
  };

  const toggleCheckPasswordVisibility = () => {
    setCheckPassword(!checkPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPasswordValue(newPassword);

    // 更新正則表達式以驗證8-16個字符的英數字混合格式
    const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;

    if (!passwordFormat.test(newPassword)) {
      setPasswordError("密碼必須為8至16個英數字混合");
    } else {
      setPasswordError("");
      // 同時檢查確認密碼是否與新密碼匹配
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError("密碼不相符");
      } else {
        setConfirmPasswordError(""); // 如果匹配則清除錯誤訊息
      }
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setConfirmPasswordError("密碼不相符");
    } else {
      setConfirmPasswordError("");
    }
  };

  // 添加提交函數
  const handleSubmit = () => {
    if (passwordError || confirmPasswordError) {
      console.log("Form has errors, submission cancelled.");
      return;
    }

    const data = {
      email: email,
      new_password: confirmPassword,
      token: token,
    };

    axios
      .post(`${apiBaseURL}/v1/user/reset_password`, data)
      .then((response) => {
        if (response && response.data) {
          console.log("Password reset successful", response.data);
          resetStatus("resetsuccess"); // 調用父組件回調以設置成功狀態
        } else {
          console.error("Unexpected response structure", response);
          resetStatus("resetfailed");
        }
      })
      .catch((error) => {
        console.error("Error resetting password", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        } else {
          console.error("An unknown error occurred");
        }
        resetStatus("resetfailed"); // 調用父組件回調以設置失敗狀態
      });
  };

  return (
    <div className="w-full grid gap-y-[24px] px-4 sm:px-8 pt-10 sm:pt-[50px] sm:h-[540px] relative">
      <div>
        <p className="text-xl sm:text-[28px] font-semibold text-[#4DA8C5]">
          重設密碼
        </p>
      </div>
      <div className="relative sm:h-[107px] mb-4 sm:mb-0">
        <label
          className="block text-gray-700 text-base mb-2"
          htmlFor="setPassword"
        >
          新密碼
        </label>
        <input
          className={`rounded-xl text-sm sm:text-base h-[40px] sm:h-[52px] w-full py-2 px-3 pr-10 Input ${
            passwordError ? "border-[#EE1D52]" : ""
          }`}
          id="setPassword"
          type={setPassword ? "text" : "password"}
          placeholder="輸入密碼"
          value={password}
          onChange={handlePasswordChange}
        />
        {passwordError && (
          <p className="text-[#EE1D52] text-sm">{passwordError}</p>
        )}
        <img
          src={setPassword ? eye : eyeclose}
          alt={setPassword ? "隱藏密碼" : "顯示密碼"}
          className="absolute right-3 top-[72%] sm:top-[55%] transform -translate-y-1/2 cursor-pointer"
          onClick={toggleSetPasswordVisibility}
        />
      </div>
      <div className="relative sm:h-[107px] mb-4 sm:mb-0">
        <label
          className="block text-gray-700 text-base mb-2"
          htmlFor="checkPassword"
        >
          確認密碼
        </label>
        <input
          className={`rounded-xl border h-[40px] sm:h-[52px] text-sm sm:text-base w-full py-2 px-3 pr-10 Input ${
            confirmPasswordError ? "border-[#EE1D52]" : ""
          }`}
          id="checkPassword"
          type={checkPassword ? "text" : "password"}
          placeholder="再次輸入密碼"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        {confirmPasswordError && (
          <p className="text-[#EE1D52] text-sm">{confirmPasswordError}</p>
        )}
        <img
          src={checkPassword ? eye : eyeclose}
          alt={checkPassword ? "隱藏密碼" : "顯示密碼"}
          className="absolute right-3 top-[72%] sm:top-[55%] transform -translate-y-1/2 cursor-pointer"
          onClick={toggleCheckPasswordVisibility}
        />
      </div>
      <button
        className="rounded-full text-base sm:text-lg h-[56px] w-full py-4 flex items-center justify-center font-bold bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white"
        onClick={handleSubmit}
      >
        下一步
      </button>
      <div className="flex justify-center py-7">
        <p className="text-base mr-1">取消重設密碼？</p>
        <Link to="/">
          <button className="text-[#4DA8C5] text-base font-semibold underline underline-offset-2">
            返回登入
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PasswordReset;
