import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ManagersLogin from "../components/ManagersLogin";
import ForgetPassword from "../components/ForgetPassword";
import EmailCode from "../components/EmailCode";
import PasswordReset from "../components/PasswordReset";
import failed from "../image/failed.svg";
import { Link } from "react-router-dom";

function FindPassword() {
  const [active, setActive] = useState(true);
  const [selectedIdentity, setSelectedIdentity] = useState("遊客");
  const [userEmail, setUserEmail] = useState("");
  const [showReset, setShowReset] = useState(false); // 新增狀態來控制顯示
  const [showPasswordReset, setShowPasswordReset] = useState(false); // 新增狀態來控制 PasswordReset 顯示
  const [token, setToken] = useState("");
  const [resetStatus, setResetStatus] = useState("");
  const navigate = useNavigate()

  const handleResetStatus = (status) => {
    setResetStatus(status);
    console.log("重設狀態:", status);
    if (status === "resetsuccess") {
      // 在成功的情況下做某些事情，例如顯示成功訊息或導航
    } else if (status === "resetfailed") {
      // 在失敗的情況下做某些事情，例如顯示錯誤訊息
    }
  };

  const handleEmailReceived = (email) => {
    setUserEmail(email);
    console.log("Received email:", email);
    setShowReset(true); // 設置為 true 以顯示 EmailCode 組件
  };

  const handleCodeSuccess = (receivedToken) => {
    setShowPasswordReset(true);
    setToken(receivedToken); // 設置 token 狀態
  };

  const handleSelectIdentity = (identity) => {
    setSelectedIdentity(identity);
  };

  const handleRoleChange = () => {
    setActive(true);
    setSelectedIdentity("遊客");
    console.log(selectedIdentity);
  };

  useEffect(() => {
    localStorage.setItem("role", selectedIdentity);
    console.log(selectedIdentity);
  }, [selectedIdentity]);

  return (
    <div className="flex justify-center items-center w-full min-h-screen max-h-max plane bg-center bg-no-repeat bg-cover px-4 py-6">
      <div className="w-full sm:w-[539px] relative mt-[60px]">
        <div className="flex">
          <div
            className={`w-7/12 sm:w-[300px] h-[61px] left-mark absolute flex flex-col justify-center items-center top-[-60px] cursor-pointer bg-white ${active ? "z-20" : "z-0 pt-7"}`}
            onClick={handleRoleChange}
          >
            <p
              className={`text-base xsm:text-xl flex relative ${active ? "gradient-underline pb-2" : "mt-5 mr-14"}`}
            >
              旅客
            </p>
            <div
              className={`${active ? "hidden" : "flex"} w-full p-6 bg-white h-[20px] ${active ? "z-20" : "z-0"}`}
            >
              .
            </div>
          </div>
          <div
            className={`w-7/12 sm:w-[300px] h-[61px] right-mark absolute flex flex-col justify-center items-center right-0 top-[-60px] cursor-pointer bg-white ${active ? "z-0  pt-7" : "z-20"}`}
            onClick={() => setActive(false)}
          >
            <p
              className={`text-base xsm:text-xl flex relative pb-2 ${active ? "mt-5 ml-14" : "gradient-underline"}`}
            >
              管理人員
            </p>
            <div
              className={`${active ? "flex" : "hidden"} w-full p-6 bg-white h-[20px] ${active ? "z-0" : "z-20"}`}
            >
              .
            </div>
          </div>
        </div>
        <div
          className="w-full sm:w-[539px] xsm:h-[603px] bg-white bottom-mark relative z-10"
          style={{
            borderRadius: active
              ? "0% 5% 5% 5% / 0% 5% 5% 5%"
              : "5% 0% 5% 5% / 5% 0% 5% 5%",
          }}
        >
          {showPasswordReset ? (
            <PasswordReset
              email={userEmail}
              token={token}
              resetStatus={handleResetStatus}
            /> // 切換到 PasswordReset 組件
          ) : showReset ? (
            <EmailCode email={userEmail} success={handleCodeSuccess} /> // 傳遞 success 方法
          ) : active ? (
            <ForgetPassword onEmailSent={handleEmailReceived} />
          ) : selectedIdentity === "遊客" ? (
            <ManagersLogin onSelectIdentity={handleSelectIdentity} />
          ) : (
            <ForgetPassword onEmailSent={handleEmailReceived} />
          )}
        </div>
      </div>
      {resetStatus === "resetfailed" && (
        <div className="bg-black/70 fixed inset-0 flex justify-center items-center z-40">
          <div className="w-[270px] sm:w-[400px] h-auto relative bg-white z-10 p-6 flex flex-col rounded-md">
            <img src={failed} alt="失敗" className="w-[40px] h-[40px]" />
            <button
              className="absolute cross w-[24px] h-[24px] right-2 top-2"
              onClick={() => setResetStatus("")}
            ></button>
            <div className="mt-[24px]">
              <p className="text-base font-bold w-[243px]">重設密碼失敗</p>
              <p className="text-[#545454] text-[14px]">
                重設密碼過程中可能遇到網路連線問題, 或是系統內部錯誤,
                請稍後再試。
              </p>
            </div>
          </div>
        </div>
      )}

      {resetStatus === "resetsuccess" && (
        <div className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
        onClick={() => {
          setResetStatus("");
          navigate("/");
        }}
        >
          <div className="w-[270px] sm:w-[400px] h-[184px] bg-white flex flex-col rounded-xl p-6 relative">
            <div className="check w-8 h-8 bg-no-repeat bg-contain mb-4"></div>
            <p className="text-lg text-[#101828] font-bold w-[243px] mb-1">
              密碼重設成功
            </p>
            <p className="text-sm text-[#475467] w-full">
              您的新密碼已成功建立，現在您可以使用新密碼登入網站。
            </p>
            <Link
              to="/"
              onClick={() => setResetStatus("")}
              className="absolute top-6 right-6 cursor-pointer"
            >
              <div className="cross w-6 h-6 bg-no-repeat bg-contain"></div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default FindPassword;
