import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient, {
  getAdminApplications,
  getApplicationExcel,
  getApplicationDetail,
  approveApplications,
  getCouriers,
  updatePickup,
  notifyCompletion,
  updateDelivery,
  approveRemittance,
  getCities,
} from "../api/apiService";
import { useEffect } from "react";

export function useLogin() {
  return useMutation({
    mutationFn: (credentials) => {
      const formData = new URLSearchParams(credentials);
      return apiClient.post("/v1/admin/login", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    },
    onSuccess: (data) => {
      localStorage.setItem("access_token", data.data.access_token);
      localStorage.setItem("login_time", Date.now().toString());
    },
  });
}

export function useAutoLogout(onLogout) {
  useEffect(() => {
    const checkLoginTime = () => {
      const loginTime = localStorage.getItem("login_time");
      if (loginTime) {
        const currentTime = Date.now();
        const timeDiff = currentTime - parseInt(loginTime);
        if (timeDiff > 120 * 60 * 1000) {
          onLogout();
        }
      }
    };

    checkLoginTime();
    const interval = setInterval(checkLoginTime, 60000);

    return () => clearInterval(interval);
  }, [onLogout]);
}

// 後台取資料
export function useAdminApplications(
  isCompleted,
  search,
  start_date,
  end_date,
  page = 1,
  pageSize = 8
) {
  return useQuery({
    queryKey: [
      "adminApplications",
      isCompleted,
      search,
      start_date,
      end_date,
      page,
      pageSize,
    ],
    queryFn: async () => {
      const response = await getAdminApplications(
        isCompleted,
        search,
        start_date,
        end_date,
        page,
        pageSize
      );
      return response;
    },
    select: (response) => response.data,
    keepPreviousData: true,
  });
}
// 後台取得 Excel
export function useApplicationExcel(isCompleted, search, start_date, end_date) {
  return useQuery({
    queryKey: ["applicationExcel", isCompleted, search, start_date, end_date],
    queryFn: async () => {
      const response = await getApplicationExcel(
        isCompleted,
        search,
        start_date,
        end_date
      );
      return {
        data: response.data, // 返回Blob
        headers: response.headers, // 返回header
      };
    },
  });
}
// 資料細節
export function useApplicationDetail(applicationId) {
  return useQuery({
    queryKey: ["applicationDetail", applicationId],
    queryFn: async () => {
      const response = await getApplicationDetail(applicationId);
      return response;
    },
    select: (response) => response.data.data,
  });
}
// 批准申辦
export function useApproveApplication() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (applicationId) => {
      const response = await approveApplications(applicationId);
      return response;
    },
    onSuccess: (data, applicationId) => {
      queryClient.invalidateQueries(["applicationDetail", applicationId]);
    },
    select: (response) => response.data,
  });
}
// 快遞員資料
export function useCouriers(
  cities = [],
  orderBy = "desc",
  page = 1,
  pageSize = 8
) {
  return useQuery({
    queryKey: ["couriers", cities, orderBy, page, pageSize],
    queryFn: async () => {
      const response = await getCouriers(cities, orderBy, page, pageSize);
      return response;
    },
    select: (response) => response.data,
  });
}

// 更新取件人
export function useUpdatePickup(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ applicationId, pickupPersonId }) => {
      const response = await updatePickup(applicationId, pickupPersonId);
      return response;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "applicationDetail",
        variables.applicationId,
      ]);

      // 調用自定義的 onSuccess
      if (options.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}
// 通知旅客申請送件
export function useNotifyCompletion() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (applicationId) => {
      const response = await notifyCompletion(applicationId);
      return response;
    },
    onSuccess: (data, applicationId) => {
      queryClient.invalidateQueries(["applicationDetail", applicationId]);
    },
    select: (response) => response.data,
  });
}

export function useUpdateDelivery(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ applicationId, deliveryPersonId }) => {
      const response = await updateDelivery(applicationId, deliveryPersonId);
      return response;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "applicationDetail",
        variables.applicationId,
      ]);

      // 調用自定義的 onSuccess
      if (options.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}

export function useApproveRemittance() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (applicationId) => {
      const response = await approveRemittance(applicationId);
      return response;
    },
    onSuccess: (data, applicationId) => {
      queryClient.invalidateQueries(["applicationDetail", applicationId]);
    },
    select: (response) => response.data,
  });
}

// 取得縣市資料
export function useCities() {
  return useQuery({
    queryKey: ["cities"],
    queryFn: async () => {
      const response = await getCities();
      return response;
    },
    select: (response) => response.data.data, // 這裡正確選取 city_id 和 city_name 的資料
  });
}
