import React, {  useState, useRef, useEffect } from "react";
import down from "../../image/down.svg";

const Dropdown = ({ label, options, selectedOption, onOptionSelect, placeholder ,icon}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col" ref={dropdownRef}>
      <span className="font-bold text-base md:text-[18px] mb-[16px]">{label}</span>
      <div className="relative">
        <div
          className="w-full px-4 py-2 border rounded-md h-[53px] bg-[#F7F7F9] border border-gray-300  flex justify-between items-center cursor-pointer"
          onClick={toggleDropdown}
        >
        <span className={`${selectedOption ? 'text-black' : 'text-gray-400'} md:text-[18px]`}>
         {selectedOption || placeholder}
        </span>
          <img
            src={icon || down} 
            alt=""
          />
        </div>
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full border rounded-md bg-[#F7F7F9] shadow-lg p-[2px] max-h-[200px] overflow-y-auto">
            {options.map((option, index) => (
              <div
                key={option}
                className={`px-4 py-2 hover:bg-[#F59762] hover:text-white md:text-[18px] cursor-pointer ${
                  index === 0 ? "hover:rounded-t-md" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


export default Dropdown;
