import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiBaseURL from "../config";

function EmailCode({ email, success }) {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [timer, setTimer] = useState(0);

  const handleEmailChange = (e) => {
    setCode(e.target.value);
    setErrorMessage("");
  };

  const handleResendCode = () => {
    if (email) {
      axios
        .post(`${apiBaseURL}/v1/user/send_reset_verification_code`, { email })
        .then((response) => {
          console.log("驗證碼已發送");
        })
        .catch((error) => {
          console.error("驗證碼發送失敗", error);
          setErrorMessage("驗證碼發送失敗，請稍後重試。");
        });
      setTimer(60);
    } else {
      setErrorMessage("Email地址未提供");
    }
  };

  const handleSubmit = () => {
    axios
      .post(`${apiBaseURL}/v1/user/verify_code`, {
        code: code,
        email: email,
      })
      .then((response) => {
        console.log("完整的回應物件：", response);
        const token = response.data.data.token; // 修正提取 token 的方式
        console.log("驗證成功, token：", token);
        success(token);
      })
      .catch((error) => {
        console.error("驗證失敗", error);
        setErrorMessage("驗證失敗，請檢查您輸入的驗證碼");
      });
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  return (
    <div className="w-full grid gap-y-[24px] px-4 xsm:px-8 pt-[50px] xsm:h-[540px] relative">
      <div>
        <p className="text-[19px] xsm:text-[28px] font-semibold text-[#4DA8C5]">
          重設密碼
        </p>
      </div>
      <div className="h-[107px]">
        <p className="xsm:text-lg text-base mb-1 h-[27px]">驗證碼</p>
        <div className="grid grid-cols-2 sm:flex">
          <input
            className={`sm:w-[273px] mr-2 h-12 sm:h-[52px] pl-2 sm:pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"} text-base Input text-[#545454]`}
            placeholder="請輸入驗證碼"
            type="text"
            value={code}
            onChange={handleEmailChange}
          />
          <button
            className={`sm:w-[180px] h-12 sm:h-[52px] text-btn01 sm:text-base font-semibold border-btn01 checkout-name rounded-xl border-[1px] ${timer > 0 ? "bg-[#DEDEDE] text-[#A3A3A3] border-none cursor-not-allowed" : "bg-white"}  focus:border-[1px] focus:border-[#4DA8C5] focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-[#545454]`}
            onClick={handleResendCode}
            disabled={timer > 0}
          >
            重新取得驗證碼
          </button>
        </div>
        {timer > 0 && (
          <div className="flex justify-end">
            <span className="font-[14px] text-[#A3A3A3]">等待 {timer} 秒</span>
          </div>
        )}
      </div>
      <button
        className={`rounded-full h-[56px] w-full mt-[80px] py-4 text-base xsm:text-lg font-medium ${
          code
            ? "bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white"
            : "bg-[#DEDEDE] text-white cursor-not-allowed"
        }`}
        disabled={!code}
        onClick={handleSubmit}
      >
        下一步
      </button>
      <div className="flex justify-center py-7">
        <p className="sm:text-base pt-0">取消重設密碼？</p>
        <Link to="/">
          <button className="text-[#4DA8C5] sm:text-base font-bold underline underline-offset-2">
            返回登入
          </button>
        </Link>
      </div>
    </div>
  );
}

export default EmailCode;
