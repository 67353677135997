import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  useCouriers,
  useUpdateDelivery,
  useApplicationDetail,
  useCities,
} from "../hook/useApi";
import arrowleft from "../images/arrowleft.svg";
import arrowright from "../images/arrowright.svg";
import backhome from "../images/backhome_black.svg";
import hasMoreArrow from "../images/hasMore.svg";
import hasMoreArrowGray from "../images/hasMore_gray.svg";

function ChooseDeliveryPage() {
  const { application_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTime, setSelectedTime] = useState(true);
  const [selectedCities, setSelectedCities] = useState([]);
  const [expandedItemId, setExpandedItemId] = useState(null);

  const orderBy = selectedTime ? "desc" : "asc";
  const { data, isLoading } = useCouriers(
    selectedCities,
    orderBy,
    currentPage,
    8
  );
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= maxPage) {
      setCurrentPage(newPage);
    }
  };
  const { data: applicationDetail } = useApplicationDetail(application_id);

  useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setDataList(data.data);
      } else {
        setDataList((prevData) => [...prevData, ...data.data]);
      }
      setMaxPage(data.total_pages || 1);
      setHasMore(currentPage < data.total_pages);
    }
  }, [data, currentPage]);

  useEffect(() => {
    if (data && currentPage === 1) {
      setDataList(data.data);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setDataList([]);
    };
  }, []);

  useEffect(() => {
    if (data && data.total_pages) {
      setMaxPage(data.total_pages || 1);
    } else {
      setMaxPage(1);
    }
  }, [data]);

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= maxPage; i++) {
      pages.push(
        <div
          key={i}
          className={`font-medium font-poppins w-10 h-10 flex justify-center items-center rounded mr-3 cursor-pointer ${i === currentPage ? "bg-btn01 text-white" : "bg-white text-btn01 border border-btn01"}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </div>
      );
    }
    return pages;
  };

  const navigate = useNavigate();
  const updateDeliveryMutation = useUpdateDelivery({
    onSuccess: (data, variables) => {
      console.log(`Updated pickup for application ${variables.applicationId}`);
      navigate(`/applicationdetail/${application_id}`);
    },
    onError: (error) => {
      console.error("Error updating pickup:", error);
    },
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data: citiesData } = useCities();
  const cities = citiesData
    ? citiesData.map((city) => ({ id: city.city_id, name: city.city_name }))
    : [];

  const handleCityChange = (cityId) => {
    setSelectedCities((prevSelectedCities) => {
      if (prevSelectedCities.includes(cityId)) {
        return prevSelectedCities.filter((id) => id !== cityId);
      } else {
        return [...prevSelectedCities, cityId];
      }
    });
  };

  const dropdownButtonRef = useRef(null);
  const dropdownContentRef = useRef(null);

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownContentRef.current &&
        !dropdownContentRef.current.contains(event.target) &&
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target)
      ) {
        handleDropdownClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-[#F7F7F9] w-full min-h-screen max-h-max">
      <div
        className={`flex w-full h-10 sm:h-[108px] z-30 sticky top-0 bg-white items-center justify-between py-6 px-4 sm:px-6 lg:px-16 shadow-md`}
      >
        <div className="flex w-auto h-10 justify-center">
          <Link to={`/applicationdetail/${application_id}`}>
            <div className="w-auto h-10 flex items-center cursor-pointer">
              <img alt="logout" src={arrowleft} className="h-5 w-6 sm:h-6" />
              <p className="hidden sm:flex text-xl font-semibold text-black pl-2">
                返回
              </p>
            </div>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <p className="hidden sm:flex text-base sm:text-xl lg:text-2xl font-bold text-black font-roboto">
            Who's Out-
          </p>
          <p className="text-base sm:text-xl lg:text-2xl font-bold text-black font-roboto">
            選擇送件人員
          </p>
        </div>
        <div className="flex w-auto h-10 justify-center">
          <Link
            to="/datamanagement"
            className="sm:w-[86px] h-10 flex items-center cursor-pointer"
          >
            <p className="sm:flex hidden text-xl font-semibold text-black pr-[6px]">
              後台
            </p>
            <img alt="logout" src={backhome} />
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center px-4 mt-6 sm:mt-10 pb-6">
        <div className="w-full xl:w-[1200px] flex flex-col">
          <div className="flex flex-col justify-start w-full relative items-center sm:items-start">
            <div
              className="w-full sm:w-[154px] rounded-[10px] h-10 px-3 border bg-white text-main-gray appearance-none cursor-pointer flex items-center justify-between"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              ref={dropdownButtonRef}
              style={{ userSelect: "none" }}
            >
              縣市篩選：
              <img
                src={arrowright}
                alt="arrow"
                className={`transform transition-transform h-4 duration-300 ${isDropdownOpen ? "rotate-90" : ""}`}
              />
            </div>
            {isDropdownOpen && (
              <div
                className="absolute top-full w-full sm:w-[639px] rounded-[10px] h-auto px-3 py-4 border text-main-gray bg-white z-10"
                style={{ userSelect: "none" }}
                ref={dropdownContentRef}
              >
                <div className="pl-1 mb-2 bg-[#A3A3A3] text-white font-semibold">
                  縣市
                </div>
                <div className="flex flex-wrap w-full justify-center sm:justify-start">
                  {cities.map((city) => (
                    <label
                      key={city.id}
                      className="flex items-center mb-2 mr-4"
                    >
                      <input
                        type="checkbox"
                        checked={selectedCities.includes(city.id)}
                        onChange={() => handleCityChange(city.id)}
                        className="mr-1 custom-checkbox"
                        style={{ width: "18px", height: "18px" }}
                      />
                      {city.name}
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="sm:flex flex-col h-[677px] mt-6 hidden">
            <div className="bg-main-gray w-full h-[45px] flex items-center text-white text-center text-lg mb-2">
              <p className="w-1/6">縣市</p>
              <p className="w-1/6">區域</p>
              <p className="w-1/6">編號</p>
              <p className="w-1/6">送件人員</p>
              <p className="w-1/6">狀態</p>
              <p className="w-1/6">選擇人員</p>
            </div>
            {data &&
              data.data.map((item) => (
                <div
                  key={item.user_id}
                  className="bg-white w-full h-[70px] mb-2 shadow-md flex items-center"
                >
                  <p className="text-main-gray text-center text-lg w-1/6">
                    {item.city}
                  </p>
                  <p className="text-main-gray text-center text-lg w-1/6">
                    {item.district}
                  </p>
                  <p className="text-main-gray text-center text-lg w-1/6">
                    {item.courier_number}
                  </p>
                  <p className="text-main-gray text-center text-lg w-1/6">
                    {item.name}
                  </p>
                  <p className="text-main-gray text-center text-lg w-1/6">
                    可送件
                  </p>
                  <div className="w-1/6">
                    <button
                      className={`w-[96%] h-[50px] hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white text-lg rounded-lg ${applicationDetail.delivery_person_id === item.user_id ? "bg-[#2F99BA]" : "bg-btn01"}`}
                      onClick={() =>
                        updateDeliveryMutation.mutate({
                          applicationId: application_id,
                          deliveryPersonId: item.user_id,
                        })
                      }
                      disabled={updateDeliveryMutation.isLoading}
                    >
                      {updateDeliveryMutation.isLoading ? "處理中..." : "送件"}
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-col w-full h-auto items-center sm:hidden">
            {dataList &&
              dataList.map((item) => (
                <div
                  className="mt-4 w-full cursor-pointer"
                  key={item.user_id}
                  onClick={() =>
                    setExpandedItemId(
                      expandedItemId === item.user_id ? null : item.user_id
                    )
                  }
                >
                  <div
                    className={`w-full h-10 rounded-t-[10px] flex items-center justify-between px-4 bg-btn01 text-white`}
                  >
                    <div className={`flex w-full justify-between`}>
                      <p className="font-semibold">
                        編號：
                        <span className="font-normal">{item.user_id}</span>
                      </p>
                      <p className="">{item.application_date}</p>
                    </div>
                  </div>
                  <div
                    className={`w-full h-auto rounded-b-[10px] flex flex-col bg-white p-4 shadow-md`}
                  >
                    <div className="flex w-full justify-between">
                      <p className="text-main-gray font-semibold">縣市：</p>
                      <p className="text-main-gray">{item.city}</p>
                    </div>
                    <div className="flex w-full justify-between mt-1">
                      <p className="text-main-gray font-semibold">區域：</p>
                      <p className="text-main-gray">{item.district}</p>
                    </div>
                    <div className="flex w-full justify-between mt-1">
                      <p className="text-main-gray font-semibold">取件人：</p>
                      <p className="text-main-gray">{item.name}</p>
                    </div>
                    <div className="flex w-full justify-between mt-1">
                      <p className="text-main-gray font-semibold">狀態：</p>
                      <p className="text-main-gray">{item.status}</p>
                    </div>
                    {expandedItemId === item.user_id && (
                      <button
                        className="text-btn01 font-semibold border border-btn01 rounded-lg py-2 mt-4"
                        onClick={() =>
                          updateDeliveryMutation.mutate({
                            applicationId: application_id,
                            deliveryPersonId: item.user_id,
                          })
                        }
                        disabled={updateDeliveryMutation.isLoading}
                      >
                        取件
                      </button>
                    )}
                  </div>
                </div>
              ))}
            {hasMore && !isLoading && (
              <div
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                className="cursor-pointer text-btn01 text-sm mt-4 flex items-center"
              >
                <img alt="hasMore" src={hasMoreArrow} className="h-4 mr-1" />
                載入更多
              </div>
            )}
            {!hasMore && (
              <div className="text-[#A3A3A3] text-sm mt-4 flex items-center">
                <img
                  alt="nothasmore"
                  src={hasMoreArrowGray}
                  className="h-4 mr-1"
                />
                沒有更多載入
              </div>
            )}
          </div>
          <div className="hidden sm:flex justify-between w-full mt-7">
            <p className="text-main-gray text-[14px]">一頁總共顯示8件</p>
            <div className="flex items-center">
              <div
                className="w-10 h-10 bg-white flex justify-center items-center rounded mr-3 cursor-pointer"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <img alt="arrowleft" src={arrowleft} className="h-4" />
              </div>
              {renderPageNumbers()}
              <div
                className="font-medium font-poppins w-10 h-10 bg-white flex justify-center items-center rounded cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <img alt="arrowright" src={arrowright} className="h-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseDeliveryPage;
